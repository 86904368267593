import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import ModalForm from 'components/shared/Modal/ModalForm';
import WorkTypeForm from './WorkTypeForm';
import TableActions from 'components/shared/table/TableActions';
import { deleteWorktypeById, editWorktype } from 'utils/functions/admin/worktype-func';
import { connect } from 'react-redux';

const WorkTypesList = ({ workTypes, duration }) => {
  const [stagedItem, setStagedItem] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(workTypes?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(workTypes?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteWorktypeById(stagedItem?.id)}
      />

      <ModalForm
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editWorktype(values, stagedItem?.id)}
      >
        <WorkTypeForm />
      </ModalForm>

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Duration</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {workTypes?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{item?.description || '-'}</td>
                <td>
                  {item?.duration} {duration?.find(d => d.id === item?.type)?.name}
                </td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    duration: state?.constants?.units?.['duration'],
  };
};

export default connect(mapStateToProps)(WorkTypesList);
