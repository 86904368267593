import React from 'react';
import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';

const UnitForm = () => {
  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'status',
      label: 'Is Active',
      type: 'checkbox',
      component: 'input',
      defaultValue: 1,
    },
  ];

  return (
    <>
      <div className='row m-0'>
        {fields
          ?.filter(i => i.component === 'input' && i.type === 'text')
          .map(item => (
            <div
              className='col-12 col-md-6'
              key={item?.id}
            >
              <div className='mt-3'>
                <label className='form-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-control'
                  type={item?.type}
                  required={item?.required === '1'}
                />
              </div>
            </div>
          ))}

        <div className='form-check col-12 col-md-6 px-5 pt-4 d-flex justify-content-between'>
          {fields
            ?.filter(i => i.type === 'checkbox')
            .map(item => (
              <div key={item?.id}>
                <label className='form-check-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-check-input'
                  type={item?.type}
                  required={item?.required === '1'}
                  defaultValue={item?.defaultValue}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default UnitForm;
