import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

// import Footer from '../components/layouts/footer/Footer';
import Header from '../components/layouts/header/Header';

const PrivateRoutes = ({ user }) => {
  return user ? (
    <>
      <Header />
      <Outlet />
      {/* <Footer /> */}
    </>
  ) : (
    <Navigate to='/' />
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user: user?.user };
}

export default connect(mapStateToProps)(PrivateRoutes);
