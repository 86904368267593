import { store } from 'app/store';
import { v4 as uuid } from 'uuid';

export const calculateDaysBetweenTwoDate = (d1, d2, days, holidays) => {
  const endDate = new Date(d2);
  let serviceDays = 0;
  if (holidays?.[0]) {
    for (let i = 0; i < days?.length; i++) {
      let startDate = new Date(d1);
      while (startDate <= endDate) {
        if (startDate.getDay() === days[i]) {
          let isHoliday = [];
          let actualDay = startDate?.getDate() + 1;
          let actualMonth = startDate?.getMonth();

          const lastDayOfMonth = getDaysArrayOfMonth(startDate.getMonth())?.[
            getDaysArrayOfMonth(startDate.getMonth())?.length - 1
          ]?.id;
          if (startDate?.getDate() >= lastDayOfMonth) {
            actualDay = 1;
            actualMonth += 1;
          }
          for (let i = 0; i < holidays?.length; i++) {
            if (Number(holidays[i]?.month) === actualMonth && Number(holidays[i]?.day) === actualDay) {
              isHoliday.push(1);
            } else {
              isHoliday.push(0);
            }
          }
          if (!isHoliday.includes(1)) {
            serviceDays++;
          }
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    }
    return serviceDays;
  } else {
    for (let i = 0; i < days?.length; i++) {
      let startDate = new Date(d1);
      while (startDate <= endDate) {
        if (startDate.getDay() === days[i]) {
          serviceDays++;
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    }
    return serviceDays;
  }
};

export const getHolidaysBetweenTwoDate = (d1, d2, days, holidays) => {
  const endDate = new Date(d2);
  let calculatedDates = [];

  if (holidays?.[0]) {
    for (let i = 0; i < days?.length; i++) {
      let startDate = new Date(d1);
      while (startDate <= endDate) {
        if (startDate.getDay() === days[i]) {
          let actualDay = startDate?.getDate() + 1;
          let actualMonth = startDate?.getMonth();
          let actualYear = startDate?.getFullYear();

          // console.log(actualYear, actualMonth, actualDay);

          const lastDayOfMonth = getDaysArrayOfMonth(startDate.getMonth(), actualYear)?.[
            getDaysArrayOfMonth(startDate.getMonth(), actualYear)?.length - 1
          ]?.id;

          if (startDate?.getDate() >= lastDayOfMonth) {
            actualDay = 1;
            if (actualMonth === 11) {
              actualMonth = 0;
              actualYear += 1;
            } else {
              actualMonth += 1;
            }
          }

          for (let i = 0; i < holidays?.length; i++) {
            if (Number(holidays[i]?.month) === actualMonth && Number(holidays[i]?.day) === actualDay) {
              const holiday = holidays?.find(
                d => Number(d?.month) === actualMonth && Number(d?.day) === actualDay
              );
              calculatedDates.push({
                id: uuid()?.slice(0, 9),
                name: holiday?.name,
                date: `${actualYear} ${Months?.find(m => m.id === Number(holiday.month))?.label} ${
                  holiday.day
                }`,
              });
            }
          }
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    }
    return calculatedDates;
  } else {
    return [];
  }
};

export const renderEmptyMessage = (emptyMessage, isLoading) => {
  return isLoading ? (
    <p className='text-center mt-3'>Loading, please wait...</p>
  ) : (
    <p className='text-center mt-3'>{emptyMessage || 'No data ...'}</p>
  );
};

export const prepareRawData = async data => {
  let allData = [];
  const allValues = data[0]?.map(item => item?.id);
  const uniqueData = [];
  for (let i = 0; i < data.length; i++) {
    allData = allData?.concat(data[i]);
    for (let j = 0; j < data[i].length; j++) {
      if (!allValues?.includes(data[i][j]?.id)) {
        allValues.push(data[i][j]?.id);
      }
    }
  }
  for (let k = 0; k < allValues.length; k++) {
    uniqueData?.push(allData?.find(d => d?.id === allValues[k]));
  }
  return uniqueData;
};

export const inputNumberValidatorPositive = value => {
  if (Number(value) >= 1) {
    return true;
  } else {
    return false;
  }
};

export const percentify = percent => {
  return Number(percent || 0)?.toFixed(2);
};

export const getWorkOrderTypeByOpportunity = type => {
  switch (Number(type)) {
    // Pool Service
    case 71:
      return 84;
    // Construction;
    case 72:
      return 85;
    // Fit Service
    case 82:
      return 86;
    // Parts & Supplies;
    case 83:
      return 89;

    default:
      break;
  }
};

export const timeStampToDate = time => {
  const date = new Date(Number(time));
  return date?.toString()?.split('G')?.[0];
};

export const timeStampToDuration = time => {
  let timestamp = Math.round(Number(time) / 1000);
  var hours = Math.floor(timestamp / 60 / 60);
  var minutes = Math.floor(timestamp / 60) - hours * 60;
  var seconds = timestamp % 60;
  var formatted =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');
  return formatted;
};

export const totalBreakDurations = breaks => {
  let sum = 0;
  if (breaks?.[0]) {
    for (let i = 0; i < breaks.length; i++) {
      if (breaks[i]?.endTime) {
        sum += Number(breaks[i]?.totalTime);
      }
    }
    return timeStampToDuration(sum);
  } else {
    return false;
  }
};

export const Months = [
  { id: 0, label: 'January' },
  { id: 1, label: 'February' },
  { id: 2, label: 'March' },
  { id: 3, label: 'April' },
  { id: 4, label: 'May' },
  { id: 5, label: 'June' },
  { id: 6, label: 'July' },
  { id: 7, label: 'August' },
  { id: 8, label: 'September' },
  { id: 9, label: 'October' },
  { id: 10, label: 'November' },
  { id: 11, label: 'December' },
];

export const getDaysArrayOfMonth = (month, year = 2024) => {
  switch (month) {
    case 0:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 1:
      return Array.from(Array(checkIsLeapYear(year) ? 29 : 28).keys())?.map(item => ({
        id: item + 1,
        label: item + 1,
      }));
    case 2:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 3:
      return Array.from(Array(30).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 4:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 5:
      return Array.from(Array(30).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 6:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 7:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 8:
      return Array.from(Array(30).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 9:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 10:
      return Array.from(Array(30).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    case 11:
      return Array.from(Array(31).keys())?.map(item => ({ id: item + 1, label: item + 1 }));
    default:
      break;
  }
};

export const checkIsLeapYear = year => {
  if ((0 === year % 4 && 0 !== year % 100) || 0 === year % 400) {
    return true;
  } else {
    return false;
  }
};

export const inputFieldTypes = [
  { value: 'text', label: 'Text' },
  { value: 'email', label: 'Email' },
  { value: 'password', label: 'Password' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio', label: 'Radio' },
  { value: 'select', label: 'Select' },
];

export const getRelatedFormGroups = data => {
  const formGroups = store?.getState()?.constants?.formGroups;
  switch (data) {
    case 23:
      return formGroups?.['asset-pool'] || [];
    case 24:
      return formGroups?.['asset-spa'] || [];
    case 25:
      return formGroups?.['asset-fountain'] || [];
    case 90:
      return formGroups?.['asset-fitness'] || [];
    default:
      return [];
  }
};

export const getFormGroupsType = data => {
  switch (data) {
    case 23:
      return 'asset-pool';
    case 24:
      return 'asset-spa';
    case 25:
      return 'asset-fountain';
    case 90:
      return 'asset-fitness';
    default:
      return '-';
  }
};
