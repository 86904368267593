import { LOG_OUT, SET_TOKEN, SET_USER } from '../actionTypes';

const initialState = {
	user: null,
	token: null,
};

const userReducer = (state = initialState, action) => {
	const { type } = action;

	switch (type) {
		case SET_TOKEN:
			return {
				...state,
				token: action?.token,
			};
		case SET_USER:
			return {
				...state,
				user: action?.user,
			};
		case LOG_OUT:
			return null;
		default:
			return state;
	}
};

export default userReducer;
