import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import TerritoryForm from './components/TerritoryForm';
import TerritoriesList from './components/TerritoriesList';
import { addTerritory } from 'utils/functions/admin/territory-func';
import { getAllTerritories } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';

const Territories = ({ getAllTerritories, territories }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Level'
        confirmBtnText='Add'
        onConfirm={values => addTerritory(values)}
      >
        <TerritoryForm />
      </ModalForm>

      <AdminHeader
        name='Territories'
        img='service_territory'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllTerritories()}
      />

      <TerritoriesList territories={territories} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    territories: state?.constants?.territories,
  };
};

export default connect(mapStateToProps, { getAllTerritories })(Territories);
