import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useState } from 'react';
import TaxGroupsList from './components/TaxGroupsList';
import { addTaxGroup } from 'utils/functions/admin/taxGroup-func';
import { getAllTaxGroups } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';
import ModalTaxGroup from 'components/shared/Modal/ModalTaxGroup';

const TaxGroups = ({ getAllTaxGroups, taxGroups }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalTaxGroup
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Tax Group'
        confirmBtnText='Add'
        onConfirm={values => addTaxGroup(values)}
      />

      <AdminHeader
        name='Tax Groups'
        img='tax_group'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllTaxGroups()}
      />

      <TaxGroupsList taxGroups={taxGroups} />
    </>
  );
};

const mapStateToProps = state => {
  return { taxGroups: state?.constants?.taxGroups };
};

export default connect(mapStateToProps, { getAllTaxGroups })(TaxGroups);
