// ********************************************************************
// ****************************** Logs ********************************
// ********************************************************************

export const showLogs = true;
// export const showLogs = false;

// ********************************************************************
// **************************** API URL *******************************
// ********************************************************************

// export const apiUrl = 'https://api.superiorpool.com/api/v1';
export const apiUrl = 'https://api.riip.ir/api/v1';

// *******************************************************************
// ********************** GOOGLE MAP API KEY *************************
// *******************************************************************

export const googleMapsApiKey = 'AIzaSyAWV1biWxYUAHnUQ9U-ihp8jJNG4e8smVA';
