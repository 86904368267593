import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useState } from 'react';
import ListOfUsers from './components/ListOfUsers';
import { getAllUsersList } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';
import ModalRole from 'components/pages/admin/users/roles/components/ModalRole';
import { addRole } from 'utils/functions/admin/role-func';

const UsersList = ({ getAllUsersList, usersList }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalRole
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New User'
        confirmBtnText='Add'
        onConfirm={values => addRole(values)}
      />

      <AdminHeader
        name='Users'
        img='lead_list'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllUsersList()}
      />

      <ListOfUsers usersList={usersList} />
    </>
  );
};

const mapStateToProps = state => {
  return { usersList: state?.constants?.usersList };
};

export default connect(mapStateToProps, { getAllUsersList })(UsersList);
