import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import TableActions from 'components/shared/table/TableActions';
import { deleteTaxGroupById, editTaxGroup } from 'utils/functions/admin/taxGroup-func';
import ModalTaxGroup from 'components/shared/Modal/ModalTaxGroup';

const TaxGroupsList = ({ taxGroups }) => {
  const [stagedItem, setStagedItem] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(taxGroups?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(taxGroups?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteTaxGroupById(stagedItem?.id)}
      />

      <ModalTaxGroup
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editTaxGroup(values, stagedItem?.id)}
      />

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Tax Group Name</th>
              <th>HST</th>
              <th>GST</th>
              <th>PST</th>
              <th>Total</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {taxGroups?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{Number(!isNaN(item?.hst) ? item?.hst : 0).toFixed(2)}</td>
                <td>{Number(!isNaN(item?.gst) ? item?.gst : 0).toFixed(2)}</td>
                <td>{Number(!isNaN(item?.pst) ? item?.pst : 0).toFixed(2)}</td>
                <td>{Number(!isNaN(item?.total) ? item?.total : 0).toFixed(2)}</td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TaxGroupsList;
