import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { getAllCategories } from 'redux/actions/constantsActions';
import { axiosHeaders } from 'utils/axiosHeaders';

export const addCategory = async (values, type) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: { ...values, type },
  };
  if (showLogs) {
    console.log('addCategory : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/category/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/category/add ', res);
  }
  if (res?.status) {
    store.dispatch(getAllCategories(true));
    toastSuccess(`The ${values?.name} has been added successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const setCategoryActivity = async (id, value) => {
  store.dispatch(setLoadingAdmin());
  const data = { data: value };

  const fetchData = await axios.post(`${apiUrl}/category/activity/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/category/activity/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllCategories(true));
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editCategory = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editCategory', data);
  }

  const fetchData = await axios.post(`${apiUrl}/category/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/category/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllCategories(true));
    toastSuccess(`The ${values?.name} has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteCategoryById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/category/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/category/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllCategories(true));
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
