import React from 'react';
import { connect } from 'react-redux';

const MainLoader = ({ isLoading, lazyLoading }) => {
  return (
    <>
      {(isLoading || lazyLoading) && (
        <div className='loader_holder position-fixed'>
          <div className='loader d-flex align-items-center justify-content-center w-100 h-100'>
            <div className='circle'></div>
            <div className='circle'></div>
            <div className='circle'></div>
            <div className='circle'></div>
            <div className='circle'></div>
          </div>
          <div className='loader_text mt-5 text-center'>Please wait ...</div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state?.loader?.isLoading,
  };
};

export default connect(mapStateToProps)(MainLoader);
