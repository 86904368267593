import {
	SET_MAIN_ITEM_ACTIVE,
	SET_PRIMARY_ACTIVE_TAB,
	SET_PRIMARY_LAST_TAB,
	SET_PRIMARY_TABS,
} from '../actionTypes';

export const setPrimaryTabs = (tabs) => (dispatch) =>
	dispatch({ type: SET_PRIMARY_TABS, tabs });
export const setPrimaryActiveTab = (tab) => (dispatch) =>
	dispatch({ type: SET_PRIMARY_ACTIVE_TAB, tab });
export const setPrimaryLastTab = (tab) => (dispatch) =>
	dispatch({ type: SET_PRIMARY_LAST_TAB, tab });

export const setMainItemActive = (item) => (dispatch) =>
	dispatch({ type: SET_MAIN_ITEM_ACTIVE, item });
