import InputValidation, { validatorConfigs } from 'components/shared/InputValidation';
import { toastError } from 'components/shared/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { AiOutlineCheck, AiOutlinePlus, AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { inputFieldTypes } from 'utils/functions/formFunctions';
import { v4 as uuid } from 'uuid';

const ModalFieldset = ({ modal, toggle, onConfirm, title, confirmBtnText, item, forEdit }) => {
  const [initialValues, setInitialValues] = useState({});
  const [addOptionMode, setAddOptionMode] = useState(false);
  const [newOption, setNewOption] = useState('');
  const [options, setOptions] = useState([]);
  const validator = useRef(new SimpleReactValidator(validatorConfigs));
  const optionInput = useRef(null);

  useEffect(() => {
    if (modal) {
      validator?.current?.hideMessages();
    }
    if (forEdit) {
      const tempOptions = item?.options;
      delete item?.[options];
      setInitialValues({ ...item });
      setOptions(JSON.parse(tempOptions));
    }
    // eslint-disable-next-line
  }, [modal]);

  const handleAddOption = () => {
    if (newOption) {
      if (!options?.map(o => o.value)?.includes(newOption)) {
        setOptions(pre => {
          return [
            ...pre,
            {
              id: uuid()?.slice(0, 9),
              label: newOption,
              value: newOption,
            },
          ];
        });
        setAddOptionMode(false);
      } else {
        toastError('There is an option with this value !');
      }
    } else {
      toastError('Please fill out all the option fields !');
    }
  };

  const handleDeleteOption = id => {
    setOptions(pre => pre?.filter(o => o.id !== id));
  };

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-center w-100'>{title} Field</Modal.Title>
          </Modal.Header>
          <Form
            initialValues={initialValues}
            onSubmit={async values => {
              if (!validator.current.allValid()) {
                validator?.current?.showMessages();
              } else {
                toggle();
                onConfirm({
                  ...values,
                  options: JSON.stringify(options || '[]'),
                });
              }
            }}
            render={({ handleSubmit, values }) => (
              <form
                onSubmit={handleSubmit}
                style={{ flex: '1 1 auto' }}
                className='d-flex flex-column'
              >
                <Modal.Body>
                  <div className='row m-0'>
                    <div className='col-12 col-md-6 mt-3'>
                      <div>
                        <label className='form-label'>Unique Key</label>
                        <Field
                          name='uniqueKey'
                          component='input'
                          className='form-control'
                          type='text'
                        />
                        <InputValidation
                          item={{
                            validation: 'alpha_num_dash|required',
                            label: 'Unique Key',
                            name: 'uniqueKey',
                          }}
                          values={values}
                          validator={validator}
                        />
                      </div>
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <div>
                        <label className='form-label'>Label</label>
                        <Field
                          name='label'
                          component='input'
                          className='form-control'
                          type='text'
                        />
                        <InputValidation
                          item={{ validation: 'required', label: 'Label', name: 'label' }}
                          values={values}
                          validator={validator}
                        />
                      </div>
                    </div>

                    <div className='form-check col-12 col-md-4 pt-4 mt-2 px-5'>
                      <div>
                        <label className='form-check-label'>Required</label>
                        <Field
                          name='required'
                          component='input'
                          className='form-check-input'
                          type='checkbox'
                          defaultValue={false}
                        />
                      </div>
                    </div>

                    <div className='form-check col-12 col-md-4 py-4 mt-2 px-5'>
                      <div>
                        <label className='form-check-label'>Hidden</label>
                        <Field
                          name='hidden'
                          component='input'
                          className='form-check-input'
                          type='checkbox'
                          defaultValue={false}
                        />
                      </div>
                    </div>

                    <div className='form-check col-12 col-md-4 pt-4 mt-2 px-5'>
                      <div>
                        <label className='form-check-label'>Is Active</label>
                        <Field
                          name='status'
                          component='input'
                          className='form-check-input'
                          type='checkbox'
                          defaultValue={true}
                        />
                      </div>
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <label className='form-label'>Class</label>
                      <Field
                        name='class'
                        component='select'
                        className='form-control'
                      >
                        <option value=''>-- Select --</option>
                        {['General', 'Partial']?.map(item => (
                          <option
                            value={item}
                            key={item}
                          >
                            {item}
                          </option>
                        ))}
                      </Field>
                      <InputValidation
                        item={{ validation: 'required', label: 'Class', name: 'class' }}
                        values={values}
                        validator={validator}
                      />
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <label className='form-label'>Input Type</label>
                      <Field
                        name='type'
                        component='select'
                        className='form-control'
                      >
                        <option value=''>-- Select --</option>
                        {inputFieldTypes?.map(item => (
                          <option
                            value={item?.value}
                            key={item?.value}
                          >
                            {item?.label}
                          </option>
                        ))}
                      </Field>
                      <InputValidation
                        item={{ validation: 'required', label: 'Input Type', name: 'type' }}
                        values={values}
                        validator={validator}
                      />
                    </div>

                    <div className='col-12 col-md-6 mt-3'>
                      <div>
                        <label className='form-label'>
                          <span className='me-1'>Validator</span>
                          <a
                            href='https://www.npmjs.com/package/simple-react-validator#rules'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <AiOutlineQuestionCircle size={16} />
                          </a>
                        </label>
                        <Field
                          name='validator'
                          component='input'
                          className='form-control'
                          type='text'
                        />
                      </div>
                    </div>

                    {['text', 'email', 'password'].includes(values?.type) ? (
                      <div className='col-12 col-md-6 mt-3'>
                        <div>
                          <label className='form-label'>Placeholder</label>
                          <Field
                            name='placeholder'
                            component='input'
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {['select', 'radio'].includes(values?.type) ? (
                      <>
                        <hr className='mt-3' />
                        <p>Options :</p>
                        <div className='d-flex row m-0 p-0 col-12 my-1'>
                          {options?.map((item, i) => (
                            <div
                              key={item?.id}
                              className='col-12 col-md-6 mb-2 py-1'
                            >
                              <div className='term_item d-flex justify-content-between rounded shadow-sm p-2 h-100'>
                                <span className='d-flex justify-content-between'>
                                  <span>{item?.label}</span>
                                </span>
                                <span>
                                  <button
                                    type='button'
                                    title='Delete this day'
                                    className='bg-transparent border-0 px-2'
                                    onClick={() => handleDeleteOption(item?.id)}
                                  >
                                    <RiDeleteBin6Line
                                      size={15}
                                      fill='indianred'
                                    />
                                  </button>
                                </span>
                              </div>
                            </div>
                          ))}

                          {addOptionMode ? (
                            <div className='col-12 col-md-6 d-flex justify-content-between align-items-center mt-2'>
                              <input
                                type='text'
                                value={newOption}
                                onChange={e => setNewOption(e?.target?.value)}
                                className='form-control me-2'
                                placeholder='Enter the Value '
                                ref={optionInput}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    handleAddOption();
                                  }
                                }}
                              />
                              <button
                                type='button'
                                className='btn btn-info text-light fw-bold mx-2'
                                onClick={() => handleAddOption()}
                              >
                                <AiOutlineCheck size={15} />
                              </button>
                              <button
                                type='button'
                                className='btn btn-light fw-bold'
                                onClick={() => setAddOptionMode(false)}
                              >
                                <RxCross2 size={15} />
                              </button>
                            </div>
                          ) : (
                            <div className='col-12 col-md-6'>
                              <button
                                type='button'
                                className='btn btn-outline-info mt-2 w-100'
                                onClick={() => {
                                  setAddOptionMode(true);
                                  setNewOption('');
                                  setTimeout(() => {
                                    optionInput?.current?.focus();
                                  }, 200);
                                }}
                              >
                                <AiOutlinePlus
                                  size={16}
                                  className='me-1'
                                />
                                Add Option
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-center w-100'>
                  <Button
                    className='mx-auto'
                    variant='secondary'
                    onClick={() => toggle(false)}
                    style={{ width: '90px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='mx-auto'
                    variant='success'
                    type='submit'
                    style={{ width: '100px' }}
                  >
                    {confirmBtnText || 'Submit'}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    resourcePriority: state?.constants?.categories?.['resource-priority'],
    fields: state?.constants?.fields?.serviceAppointment,
  };
};

export default connect(mapStateToProps, {})(ModalFieldset);
