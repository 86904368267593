import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { axiosHeaders } from 'utils/axiosHeaders';
import { SET_WORK_TYPES } from 'redux/actionTypes';

export const addWorktype = async values => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };
  if (showLogs) {
    console.log('addWorktype : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/worktype/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/worktype/add ', res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_WORK_TYPES,
      workTypes: res?.data,
    });
    toastSuccess(`The ${values?.name} has been added successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editWorktype = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editWorktype', data);
  }

  const fetchData = await axios.post(`${apiUrl}/worktype/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/worktype/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_WORK_TYPES,
      workTypes: res?.data,
    });
    toastSuccess(`The ${values?.name} Worktype has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteWorktypeById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/worktype/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/worktype/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_WORK_TYPES,
      workTypes: res?.data,
    });
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
