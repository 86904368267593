import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const SubMenu = ({ item, openedItem, selectMenu }) => {
  const location = useLocation();

  return (
    <>
      <Link
        className={classNames(
          'sidebar_link w-100 d-flex justify-content-between align-items-center pt-3 border-top',
          {
            active: location?.pathname === item?.path,
          }
        )}
        to={item.subNav ? null : item.path}
        onClick={() => selectMenu(item)}
      >
        <div>
          {item?.img ? (
            <img
              alt=''
              className='icon'
              src={`${process.env.PUBLIC_URL}/assets/img/icons/${item?.img}.png`}
            />
          ) : (
            item.icon
          )}
          <span className='ms-2'>{item.title}</span>
        </div>
        <div>
          {item?.subNav ? (
            <>{openedItem === item?.id ? <BsChevronUp size={14} /> : <BsChevronDown size={14} />}</>
          ) : (
            ''
          )}
        </div>
      </Link>
      {item?.subNav?.map((itm, index) => (
        <Link
          className={classNames('dropdown_link w-100 align-items-center border-start', {
            show: openedItem === item?.id,
            active: location?.pathname === itm?.path,
          })}
          to={itm.path}
          key={index}
          style={{ height: openedItem === item?.id ? '30px' : '0px' }}
        >
          {itm?.img ? (
            <img
              alt=''
              className='icon'
              src={`${process.env.PUBLIC_URL}/assets/img/icons/${itm?.img}.png`}
            />
          ) : (
            itm.icon
          )}
          <span className='ms-2'>{itm.title}</span>
        </Link>
      ))}
    </>
  );
};

export default SubMenu;
