import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useState } from 'react';
import HolidayGroupsList from './components/HolidayGroupsList';
import { addHolidayGroup } from 'utils/functions/admin/holidayGroup-func';
import { getAllHolidayGroups } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';
import ModalHolidayGroup from './components/ModalHolidayGroup';

const HolidayGroups = ({ getAllHolidayGroups, holidayGroups }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalHolidayGroup
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Holiday Group'
        confirmBtnText='Add'
        onConfirm={values => addHolidayGroup(values)}
      />

      <AdminHeader
        name='Holiday Groups'
        img='holiday'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllHolidayGroups()}
      />

      <HolidayGroupsList holidayGroups={holidayGroups} />
    </>
  );
};

const mapStateToProps = state => {
  return { holidayGroups: state?.constants?.holidayGroups };
};

export default connect(mapStateToProps, { getAllHolidayGroups })(HolidayGroups);
