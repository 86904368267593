import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AiOutlineCheck, AiOutlinePlus } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toastError } from 'components/shared/toast';
import { Months, getDaysArrayOfMonth } from 'utils/functions/formFunctions';

const ModalHolidayGroup = ({ modal, toggle, onConfirm, initialValues, confirmBtnText, title }) => {
  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'status',
      label: 'Is Active',
      type: 'checkbox',
      component: 'input',
      defaultValue: 1,
    },
  ];

  const holidayNameInput = useRef(null);
  const [addDayMode, setAddDayMode] = useState(false);
  const [allHolidays, setAllHolidays] = useState([]);

  const [newHolidayName, setNewHolidayName] = useState('');
  const [newHolidayMonth, setNewHolidayMonth] = useState('');
  const [newHolidayDay, setNewHolidayDay] = useState('');

  const onAddNewHoliday = () => {
    if (newHolidayMonth && newHolidayName) {
      const tempHolidays = allHolidays;
      tempHolidays?.push({
        id: uuid()?.slice(0, 6),
        name: newHolidayName,
        month: newHolidayMonth,
        day: newHolidayDay,
      });
      setAllHolidays(tempHolidays);
      setAddDayMode(false);
    }
  };

  const onDeleteDay = id => {
    setAllHolidays(pre => pre?.filter(t => t?.id !== id));
  };

  useEffect(() => {
    if (initialValues?.days && modal) {
      setAllHolidays(JSON.parse(initialValues?.days || '[]'));
    }
    // eslint-disable-next-line
  }, [modal]);

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
          className='modal_md'
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-center w-100 border-bottom pb-3'>{title}</Modal.Title>
          </Modal.Header>

          <Form
            initialValues={initialValues}
            onSubmit={values => {
              if (allHolidays?.length === 0) {
                toastError('Please add days to the list');
              } else {
                toggle();
                onConfirm({
                  ...values,
                  days: JSON.stringify(allHolidays),
                });
              }
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className='row m-0'>
                    {fields
                      ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
                      .map(item => (
                        <div
                          className='col-12 col-md-5'
                          key={item?.id}
                        >
                          <div className='mt-3'>
                            <label className='form-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-control'
                              type={item?.type}
                              required={item?.required === '1'}
                            />
                          </div>
                        </div>
                      ))}

                    <div className='form-check col-12 col-md-2 mt-4 py-4 px-5 d-flex justify-content-between'>
                      {fields
                        ?.filter(i => i.type === 'checkbox')
                        .map(item => (
                          <div key={item?.id}>
                            <label className='form-check-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-check-input'
                              type={item?.type}
                              required={item?.required === '1'}
                              defaultValue={item?.defaultValue}
                            />
                          </div>
                        ))}
                    </div>
                  </div>

                  <hr />

                  <h5 className=''>Days that are closed in this group :</h5>

                  <div className='d-flex row m-0 p-0 col-12 my-2 py-1 mt-3'>
                    {allHolidays?.map((item, i) => (
                      <div
                        key={item?.id}
                        className='col-12 col-md-6 my-2 py-1'
                      >
                        <div className='term_item d-flex justify-content-between rounded shadow-sm p-2 h-100'>
                          <span>
                            <span className='fs-5'>{i + 1}</span> -{' '}
                            <span className='fw-bold'>{item?.name} </span>
                            on{' '}
                            <em>
                              {Months?.find(m => m.id === Number(item?.month))?.label} {item?.day}
                            </em>
                          </span>
                          <span>
                            <button
                              type='button'
                              title='Delete this day'
                              className='bg-transparent border-0 px-2'
                              onClick={() => onDeleteDay(item?.id)}
                            >
                              <RiDeleteBin6Line
                                size={15}
                                fill='indianred'
                              />
                            </button>
                          </span>
                        </div>
                      </div>
                    ))}

                    {addDayMode ? (
                      <div className='d-flex justify-content-between align-items-center w-50 mt-3'>
                        <input
                          type='text'
                          ref={holidayNameInput}
                          value={newHolidayName}
                          onChange={e => setNewHolidayName(e?.target?.value)}
                          className='form-control'
                          placeholder='Enter the name '
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              onAddNewHoliday();
                            }
                          }}
                        />
                        <select
                          value={newHolidayMonth}
                          onChange={e => setNewHolidayMonth(e?.target?.value)}
                          className='form-control ms-2'
                          style={{ width: '40%' }}
                        >
                          <option value=''>Month</option>
                          {Months?.map(item => (
                            <option
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        <select
                          value={newHolidayDay}
                          onChange={e => setNewHolidayDay(e?.target?.value)}
                          className='form-control ms-2'
                          style={{ width: '25%' }}
                        >
                          <option value=''>Day</option>
                          {getDaysArrayOfMonth(Number(newHolidayMonth))?.map(item => (
                            <option
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        <button
                          type='button'
                          className='btn btn-info text-light fw-bold mx-2'
                          onClick={() => onAddNewHoliday()}
                        >
                          <AiOutlineCheck size={15} />
                        </button>
                        <button
                          type='button'
                          className='btn btn-light fw-bold'
                          onClick={() => setAddDayMode(false)}
                        >
                          <RxCross2 size={15} />
                        </button>
                      </div>
                    ) : (
                      <div className='col-12 col-md-6'>
                        <button
                          type='button'
                          className='btn btn-outline-info mt-2 w-100'
                          onClick={() => {
                            setAddDayMode(true);
                            setNewHolidayName('');
                            setTimeout(() => {
                              holidayNameInput?.current?.focus();
                            }, 200);
                          }}
                        >
                          <AiOutlinePlus
                            size={16}
                            className='me-1'
                          />
                          Add Day
                        </button>
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                </Modal.Body>
                <Modal.Footer className='text-center w-100 mt-3'>
                  <Button
                    className='mx-auto'
                    variant='secondary'
                    onClick={() => toggle(false)}
                    style={{ width: '80px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='mx-auto'
                    variant='success'
                    type='submit'
                    style={{ width: '110px' }}
                  >
                    {confirmBtnText}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ModalHolidayGroup);
