import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { getAllFieldsets } from 'redux/actions/constantsActions';
import { axiosHeaders } from 'utils/axiosHeaders';

export const addFieldset = async (values, object) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: { ...values, object },
  };
  if (showLogs) {
    console.log('addFieldset : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/fieldset/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/fieldset/add ', res);
  }
  if (res?.status) {
    store.dispatch(getAllFieldsets(true));
    toastSuccess(`The ${values?.label} has been added successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const setFieldsetActivity = async (id, value) => {
  store.dispatch(setLoadingAdmin());
  const data = { data: value };

  const fetchData = await axios.post(`${apiUrl}/fieldset/activity/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/fieldset/activity/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllFieldsets(true));
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editFieldset = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editFieldset', data);
  }

  const fetchData = await axios.post(`${apiUrl}/fieldset/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/fieldset/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllFieldsets(true));
    toastSuccess(`The ${values?.label} has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteFieldsetById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/fieldset/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/fieldset/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllFieldsets(true));
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
