import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useState } from 'react';
import RolesList from './components/RolesList';
import { getAllRoles } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';
import ModalRole from 'components/pages/admin/users/roles/components/ModalRole';
import { addRole } from 'utils/functions/admin/role-func';

const Roles = ({ getAllRoles, roles }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalRole
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Role'
        confirmBtnText='Add'
        onConfirm={values => addRole(values)}
      />

      <AdminHeader
        name='Roles and Permissions'
        img='user_role'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllRoles()}
      />

      <RolesList roles={roles} />
    </>
  );
};

const mapStateToProps = state => {
  return { roles: state?.constants?.roles };
};

export default connect(mapStateToProps, { getAllRoles })(Roles);
