import axios from 'axios';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router';
import { apiUrl, showLogs } from 'app/Rules';
import { toastError, toastSuccess } from 'components/shared/toast';
import { axiosHeaders } from 'utils/axiosHeaders';
import { connect } from 'react-redux';
import { setToken, setUser } from 'redux/actions/userActions';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';

const LoginForm = ({ setToken, setUser }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onLogin = values => {
    setIsLoading(true);
    if (showLogs) {
      console.log(values);
    }

    setTimeout(() => {
      const data = new FormData();
      data.append('email', values?.email);
      data.append('password', values?.password);

      axios
        .post(`${apiUrl}/auth/login`, data, {
          headers: axiosHeaders,
        })
        .then(data => {
          setIsLoading(false);
          if (showLogs) {
            console.log(data?.data);
          }
          if (data?.status === 200) {
            setToken(data?.data?.access_token);
            setUser(data?.data?.user_info);
            localStorage.setItem('token', data?.data?.access_token);
            toastSuccess(`Welcome ${data?.data?.user_info?.email}`);
            navigate('/dashboard');
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          // console.log(err?.response?.data?.message);
          toastError();
        });
    }, 1000);
  };

  return (
    <Form
      onSubmit={onLogin}
      // validate={validate}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className='login_form text-light'
        >
          <div className='my-3 mx-auto'>
            <label className='form-label'>Email</label>
            <Field
              name='email'
              component='input'
              placeholder='Enter your Email address'
              className='form-control'
              type='email'
              required
            />
          </div>
          <div className='my-3 mx-auto'>
            <label className='form-label'>Password</label>
            <Field
              name='password'
              component='input'
              placeholder='Enter your Password'
              className='form-control'
              type='password'
              required
            />
          </div>
          <div className='text-center mt-5 mx-auto'>
            <button
              type='submit'
              className='btn btn-info w-100 text-light fw-bold'
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span>Please Wait ... </span>
                  <Spinner
                    animation='border'
                    size='sm'
                    variant='light'
                  />
                </>
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
      )}
    />
  );
};
export default connect(null, { setToken, setUser })(LoginForm);
