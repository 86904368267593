import React, { useState } from 'react';
import { editFieldset, setFieldsetActivity } from 'utils/functions/admin/fieldset-func';
import { RiEdit2Line } from 'react-icons/ri';
import { Form } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineCheck } from 'react-icons/ai';
import ModalFieldset from './ModalFieldset';

const FieldsetItem = ({ field }) => {
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => {
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalFieldset
        modal={modalEdit}
        toggle={toggleEdit}
        title='Edit'
        confirmBtnText='Save'
        item={field}
        onConfirm={values => editFieldset(values, field?.id)}
        forEdit
      />

      <div className='term_item position-relative d-flex flex-column justify-content-between rounded shadow-sm p-2 h-100'>
        <div className='d-flex flex-column w-100'>
          <p className='fw-bold ps-2 text-center'>{field?.label}</p>
          <p className='mb-2'>
            <span className='me-2 fst-italic fw-light'>Type : </span>
            <span>{field?.type}</span>
          </p>
          <p className='mb-2'>
            <span className='me-2 fst-italic fw-light'>Key : </span>
            <span>{field?.uniqueKey}</span>
          </p>
          {field?.class && (
            <p className='mb-2'>
              <span className='me-2 fst-italic fw-light'>Class : </span>
              <span>{field?.class}</span>
            </p>
          )}
          {field?.placeholder && (
            <p className='mb-2'>
              <span className='me-2 fst-italic fw-light'>Placeholder : </span>
              <span>{field?.placeholder}</span>
            </p>
          )}
          {field?.validator && (
            <p className='mb-2'>
              <span className='me-2 fst-italic fw-light'>Validator : </span>
              <span>{field?.validator}</span>
            </p>
          )}
          <p className='mb-2'>
            <span className='me-2 fst-italic fw-light'>Required : </span>
            <span>
              {field?.required === 0 ? (
                <RxCross2
                  color='darkred'
                  size={13}
                />
              ) : (
                <AiOutlineCheck
                  fill='green'
                  size={13}
                />
              )}
            </span>
          </p>
          <p className='mb-2'>
            <span className='me-2 fst-italic fw-light'>Hidden : </span>
            <span>
              {field?.hidden === 0 ? (
                <RxCross2
                  color='darkred'
                  size={13}
                />
              ) : (
                <AiOutlineCheck
                  fill='green'
                  size={13}
                />
              )}
            </span>
          </p>
        </div>
        <div className='d-flex align-items-end justify-content-around'>
          <hr />
          <Form.Check
            type='switch'
            label={`${field?.status === 1 ? 'Active' : 'Deactive'}`}
            checked={field?.status === 1 ? 1 : 0}
            onChange={() => setFieldsetActivity(field?.id, field?.status === 0 ? 1 : 0)}
            className='activity_switch'
          />
          <button
            type='button'
            title='Edit this field'
            className='bg-transparent border-0 px-2'
            onClick={() => toggleEdit(field?.id)}
          >
            <RiEdit2Line size={15} />
          </button>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default FieldsetItem;
