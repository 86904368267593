import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import SchedulingConstraintForm from './components/SchedulingConstraintForm';
import SchedulingConstraintList from './components/SchedulingConstraintList';
import { getAllSchedulingConstraints } from 'redux/actions/constantsActions';
import { addSchedulingConstraints } from 'utils/functions/admin/constraints-func';
import { connect } from 'react-redux';

const SchedulingConstraints = ({ constraints, getAllSchedulingConstraints }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Scheduling Constraint'
        confirmBtnText='Add'
        onConfirm={values => addSchedulingConstraints(values)}
      >
        <SchedulingConstraintForm />
      </ModalForm>

      <AdminHeader
        name='Scheduling Constraints'
        img='scheduling_constraints'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllSchedulingConstraints()}
      />

      <SchedulingConstraintList constraints={constraints} />
    </>
  );
};

const mapStateToProps = state => {
  return { constraints: state?.constants?.schedulingConstraints };
};

export default connect(mapStateToProps, { getAllSchedulingConstraints })(SchedulingConstraints);
