import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import ModalForm from 'components/shared/Modal/ModalForm';
import OperatingHoursForm from './OperatingHourForm';
import TableActions from 'components/shared/table/TableActions';
import { deleteOperatingHourById, editOperatingHour } from 'utils/functions/admin/operating_hours-func';
import { connect } from 'react-redux';

const OperatingHoursList = ({ operatingHours }) => {
  const [stagedItem, setStagedItem] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(operatingHours?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(operatingHours?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteOperatingHourById(stagedItem?.id)}
      />

      <ModalForm
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editOperatingHour(values, stagedItem?.id)}
      >
        <OperatingHoursForm />
      </ModalForm>

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Time Zone</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {operatingHours?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{item?.time_zone}</td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    constraints: state?.constants?.schedulingConstraints,
    operatingHours: state?.constants?.operatingHours,
  };
};

export default connect(mapStateToProps)(OperatingHoursList);
