import { useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import Sidebar from 'components/layouts/sidebar/Sidebar';
import classNames from 'classnames';
import AdminLoader from 'components/shared/loader/AdminLoader';

const AdminRoutes = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);
  return user ? (
    <>
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <div
        className={classNames('admin p-3', {
          collapsed,
        })}
      >
        <AdminLoader />
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to='/' />
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user: user?.user };
}

export default connect(mapStateToProps)(AdminRoutes);
