import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { getAllOptions } from 'redux/actions/constantsActions';
import { axiosHeaders } from 'utils/axiosHeaders';

export const addOption = async (values, type) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: { ...values, type },
  };
  if (showLogs) {
    console.log('addOption : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/options/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/options/add ', res);
  }
  if (res?.status) {
    store.dispatch(getAllOptions(true));
    toastSuccess(`The ${values?.name} has been added successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editOption = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editOption', data);
  }

  const fetchData = await axios.post(`${apiUrl}/options/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/options/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch(getAllOptions(true));
    toastSuccess(`The ${values?.name} has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

// export const setOptionActivity = async (id, value) => {
//   store.dispatch(setLoadingAdmin());
//   const data = { data: value };

//   const fetchData = await axios.post(`${apiUrl}/options/activity/${id}`, data, {
//     headers: axiosHeaders,
//   });
//   const res = fetchData?.data;
//   store.dispatch(clearLoadingAdmin());
//   if (showLogs) {
//     console.log(`/options/activity/${id}`, res);
//   }
//   if (res?.status) {
//     store.dispatch(getAllOptions(true));
//     toastSuccess(res?.message, 2500);
//     return res?.status;
//   } else {
//     toastError();
//     return {};
//   }
// };

// export const deleteOptionById = async id => {
//   store.dispatch(setLoadingAdmin());

//   const fetchData = await axios.post(`${apiUrl}/options/delete/${id}`, {
//     headers: axiosHeaders,
//   });
//   const res = fetchData?.data;
//   store.dispatch(clearLoadingAdmin());
//   if (showLogs) {
//     console.log(`/options/delete/${id}`, res);
//   }
//   if (res?.status) {
//     store.dispatch(getAllOptions(true));
//     toastSuccess(res?.message, 2500);
//     return res?.status;
//   } else {
//     toastError();
//     return {};
//   }
// };
