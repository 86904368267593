import React from 'react';
import { LoadScript, GoogleMap } from '@react-google-maps/api';

const MapViewer = ({ object, type }) => {
  const mapContainerStyle = {
    height: '250px',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  };

  const data = () => {
    switch (type) {
      case 'billing':
        return {
          lat: Number(object?.billing_latitude),
          lng: Number(object?.billing_longitude),
        };

      case 'shipping':
        return {
          lat: Number(object?.shipping_latitude),
          lng: Number(object?.shipping_longitude),
        };

      case 'mailing':
        return {
          lat: Number(object?.mailing_latitude),
          lng: Number(object?.mailing_longitude),
        };
      case 'location':
        return {
          lat: Number(object?.location_latitude),
          lng: Number(object?.location_longitude),
        };

      default:
        break;
    }
  };

  const zoom = data()?.lat ? 15 : 0.5;
  const ca_lat = 45.4;
  const ca_lng = -75.666667;
  const id = `mapview_${data()?.lat || ca_lat}_${data()?.lng || ca_lng}`;

  const center = {
    lat: Number(data()?.lat || ca_lat),
    lng: Number(data()?.lng || ca_lng),
  };

  return (
    <>
      {window.google === undefined && type ? (
        <LoadScript
          googleMapsApiKey='AIzaSyAWV1biWxYUAHnUQ9U-ihp8jJNG4e8smVA'
          libraries={['places']}
        >
          <GoogleMap
            id={id}
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={center}
          />
        </LoadScript>
      ) : (
        <GoogleMap
          id={id}
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          center={center}
        />
      )}
    </>
  );
};

export default MapViewer;
