import React, { useState } from 'react';
import AppTap from 'components/shared/tab/AppTap';
import { FaBars } from 'react-icons/fa';
import { SidebarData } from './components/sidebarData';
import SubMenu from './components/SubMenu';
import classNames from 'classnames';

const Sidebar = ({ collapsed, setCollapsed }) => {
  const [openedItem, setOpenedItem] = useState(null);

  const showSidebar = () => setCollapsed(!collapsed);

  const selectMenu = menu => {
    if (menu?.id === openedItem) {
      setOpenedItem(null);
    } else {
      setOpenedItem(menu?.id);
    }
  };

  return (
    <>
      <div
        className={classNames('sidebar position-fixed py-3 ps-1', {
          'collapsed pe-0': collapsed,
          'pe-4': !collapsed,
        })}
      >
        <div className='d-flex justify-content-between'>
          <AppTap />
          <button
            type='button'
            className='border-0 bg-transparent p-2'
          >
            <FaBars
              onClick={showSidebar}
              size={24}
            />
          </button>
        </div>

        <nav className='sidebar_nav d-flex justify-content-center position-fixed ps-2 mt-4'>
          <div className='sidebar_wrap'>
            {SidebarData.map(item => {
              return (
                <SubMenu
                  item={item}
                  key={item?.title}
                  openedItem={openedItem}
                  setOpenedItem={setOpenedItem}
                  selectMenu={selectMenu}
                />
              );
            })}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
