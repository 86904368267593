import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useEffect, useState } from 'react';
import AssetTypesList from './components/AssetTypesList';
import { connect } from 'react-redux';
import { addCategory } from 'utils/functions/admin/category-func';
import { getAllCategories, getAllFieldsets } from 'redux/actions/constantsActions';
import ModalAssetTypes from './components/ModalAssetTypes';

const AssetTypes = ({ data, getAllCategories, getAllFieldsets }) => {
  const [dataShowing, setDataShowing] = useState(data);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  useEffect(() => {
    setDataShowing(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <ModalAssetTypes
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Asset Type'
        confirmBtnText='Add'
        onConfirm={values => addCategory(values, 'asset-types')}
      />

      <AdminHeader
        name='Asset Types'
        img='asset_object'
        onAddNew={() => toggleAdd()}
        onUpdate={() => {
          getAllCategories(true);
          getAllFieldsets(true);
        }}
      />

      <AssetTypesList data={dataShowing} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.categories?.['asset-types'],
  };
};

export default connect(mapStateToProps, { getAllCategories, getAllFieldsets })(AssetTypes);
