import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import TableActions from 'components/shared/table/TableActions';
import { AiOutlineCheck } from 'react-icons/ai';
import ModalRole from 'components/pages/admin/users/roles/components/ModalRole';
import { deleteRoleById, editRole } from 'utils/functions/admin/role-func';

const ListOfUsers = ({ usersList }) => {
  const [stagedItem, setStagedItem] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(usersList?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      let rawData = usersList?.find(u => u.id === id);
      setStagedItem(rawData);
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteRoleById(stagedItem?.id)}
      />

      <ModalRole
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editRole(values, stagedItem?.id)}
      />

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Role</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {usersList?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{item?.description || '-'}</td>
                <td>
                  {item?.status ? (
                    <AiOutlineCheck
                      fill='green'
                      size={16}
                    />
                  ) : (
                    'Deactive'
                  )}
                </td>{' '}
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ListOfUsers;
