import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import UnitForm from './components/UnitForm';
import UnitsList from './components/UnitsList';
import { connect } from 'react-redux';
import { addUnit } from 'utils/functions/admin/unit-func';
import { getAllUnits } from 'redux/actions/constantsActions';

const Units = ({ type, img, name, data, getAllUnits }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title={`Add New ${name}`}
        confirmBtnText='Add'
        onConfirm={values => addUnit(values, type)}
      >
        <UnitForm />
      </ModalForm>

      <AdminHeader
        name={name}
        img={img}
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllUnits(true)}
      />

      <UnitsList data={data?.[type]} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.units,
  };
};

export default connect(mapStateToProps, { getAllUnits })(Units);
