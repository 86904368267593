import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { RiEdit2Line } from 'react-icons/ri';
import { uploadTemplate } from 'utils/functions/main';

const TemplateCard = ({ template }) => {
  const [file, setFile] = useState();
  const [uploadMode, setUploadMode] = useState(false);

  useEffect(() => {
    if (template?.value) {
      setUploadMode(false);
    } else {
      setUploadMode(true);
    }
    // eslint-disable-next-line
  }, [template]);

  const handleUploadTemplate = async () => {
    if (file?.name) {
      await uploadTemplate(file, template?.id);
      setUploadMode(false);
      setFile('');
    }
  };

  return (
    <>
      <div
        className='term_item position-relative d-flex flex-column justify-content-around rounded shadow-sm p-2'
        style={{ height: '130px' }}
      >
        <div className='d-flex flex-column w-100'>
          <h5 className='text-center mt-1'>{template?.name}</h5>
          <hr className='mt-1' />
        </div>
        {uploadMode ? (
          <div className='d-flex flex-column align-items-around h-100'>
            <input
              type='file'
              className='form-control'
              // value={file}
              onChange={e => setFile(e?.target?.files?.[0])}
              required
            />
            <div className='d-flex justify-content-around'>
              {template?.value && (
                <Button
                  className='mx-auto mt-3'
                  variant='secondary'
                  style={{ width: '80px' }}
                  onClick={() => setUploadMode(false)}
                >
                  cancel
                </Button>
              )}
              <Button
                className='mx-auto mt-3'
                type='submit'
                variant='success'
                style={{ width: '120px' }}
                onClick={() => handleUploadTemplate()}
              >
                Upload
              </Button>
            </div>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-around h-100'>
            <p className='mb-0'>{template?.value}</p>
            <button
              type='button'
              title='Edit this template'
              className='bg-transparent border-0 px-2'
              onClick={() => setUploadMode(true)}
            >
              <RiEdit2Line size={20} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateCard;
