import React from 'react';

export const validatorConfigs = {
  element: message => <div className='input_validator'>{message}</div>,
  messages: {
    alpha_num_dash: 'Only letters, numbers and dashes',
  },
};

const InputValidation = ({ item, values, validator }) => {
  return (
    <div style={{ height: '5px' }}>
      {item?.validation ? (
        <div className='input_validator'>
          {validator.current.message(`${item?.label}`, values[item?.name], item?.validation)}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default InputValidation;
