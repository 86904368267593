import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import SkillForm from './components/SkillForm';
import SkillsList from './components/SkillsList';
import { addSkill } from 'utils/functions/admin/skill-func';
import { getAllSkills } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';

const Skills = ({ getAllSkills, skills }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Skill'
        confirmBtnText='Add'
        onConfirm={values => addSkill(values)}
      >
        <SkillForm />
      </ModalForm>

      <AdminHeader
        name='Skills'
        img='skill'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllSkills()}
      />

      <SkillsList skills={skills} />
    </>
  );
};

const mapStateToProps = state => {
  return { skills: state?.constants?.skills };
};

export default connect(mapStateToProps, { getAllSkills })(Skills);
