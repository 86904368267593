// Loader
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_LOADING_ADMIN = 'SET_LOADING_ADMIN';
export const CLEAR_LOADING_ADMIN = 'CLEAR_LOADING_ADMIN';

// THEME
export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';

// USER
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const LOG_OUT = 'LOG_OUT';

// CONSTANTS
export const SET_UNITS = 'SET_UNITS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FORM_GROUPS = 'SET_FORM_GROUPS';
export const SET_SKILLS = 'SET_SKILLS';
export const SET_WORK_TYPES = 'SET_WORK_TYPES';
export const SET_LEVELS = 'SET_LEVELS';
export const SET_TAX_GROUPS = 'SET_TAX_GROUPS';
export const SET_HOLIDAY_GROUPS = 'SET_HOLIDAY_GROUPS';
export const SET_ROLES = 'SET_ROLES';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_TERRITORIES = 'SET_TERRITORIES';
export const SET_OPERATING_HOURS = 'SET_OPERATING_HOURS';
export const SET_SCHEDULING_CONSTRAINTS = 'SET_SCHEDULING_CONSTRAINTS';
export const SET_ACCOUNT_FIELDS = 'SET_ACCOUNT_FIELDS';
export const SET_CONTACT_FIELDS = 'SET_CONTACT_FIELDS';
export const SET_SERVICE_CONTRACT_FIELDS = 'SET_SERVICE_CONTRACT_FIELDS';
export const SET_MAINTENANCE_PLANS_FIELDS = 'SET_MAINTENANCE_PLANS_FIELDS';
export const SET_CASE_FIELDS = 'SET_CASE_FIELDS';
export const SET_OPPORTUNITY_FIELDS = 'SET_OPPORTUNITY_FIELDS';
export const SET_QUOTES_FIELDS = 'SET_QUOTES_FIELDS';
export const SET_WORK_ORDER_FIELDS = 'SET_WORK_ORDER_FIELDS';
export const SET_SERVICE_APPOINTMENTS_FIELDS = 'SET_SERVICE_APPOINTMENTS_FIELDS';
export const SET_ALL_FIELDS = 'SET_ALL_FIELDS';

// MAIN
export const SET_PRICEBOOKS = 'SET_PRICEBOOKS';
export const SET_SERVICE_RESOURCES = 'SET_SERVICE_RESOURCES';

// TABS
export const SET_PRIMARY_TABS = 'SET_PRIMARY_TABS';
export const SET_PRIMARY_ACTIVE_TAB = 'SET_PRIMARY_ACTIVE_TAB';
export const SET_PRIMARY_LAST_TAB = 'SET_PRIMARY_LAST_TAB';

export const SET_MAIN_ITEMS = 'SET_MAIN_ITEMS';
export const SET_MAIN_ITEM_ACTIVE = 'SET_MAIN_ITEM_ACTIVE';
