import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { setLoading, clearLoading, setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { axiosHeaders, axiosHeadersFormData } from '../axiosHeaders';
import { SET_OPTIONS } from 'redux/actionTypes';
import { prepareRawData } from './formFunctions';
import { optionTypes } from 'redux/reducers/constantsReducer';
import { toastSuccess } from 'components/shared/toast';

// -----  COMMON --------

export const getAccountData = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/select_account/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/select_account/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

export const getObjectById = async (id, type) => {
  store.dispatch(setLoading());
  const data = await axios.post(`${apiUrl}/${type}/get/${id}`, {
    headers: axiosHeaders,
  });
  store.dispatch(clearLoading());
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/get/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

export const setStageByObjectId = async (id, stage, type) => {
  store.dispatch(setLoading());

  const data = {
    data: { stage },
  };

  if (showLogs) {
    console.log('setStageByObjectID : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/${type}/setStageByID/${id}`, data, {
    headers: axiosHeaders,
  });
  store.dispatch(clearLoading());
  const res = fetchData?.data;
  if (showLogs) {
    console.log(`/${type}/setStageByID/${id}`, res);
  }
  if (res?.status) {
    toastSuccess(`The Stage has been modified successfully!`);
    return res?.data;
  } else {
    return {};
  }
};

export const getStandardPricebook = async () => {
  const reqData = {
    data: {
      tbl: 'pricebook',
      col: ['IsStandard'],
      op: 'LIKE',
      val: 1,
    },
  };

  const data = await axios.post(`${apiUrl}/search/dropdown`, reqData, {
    headers: axiosHeaders,
  });

  const rawData = await prepareRawData(data?.data?.data);
  if (rawData?.[0]) {
    return rawData;
  }
};

export const editField = async (item, type, field, value) => {
  store.dispatch(setLoading());
  item[field] = value;

  const data = {
    data: { ...item },
  };

  console.log(data?.data);

  const fetchData = await axios.post(`${apiUrl}/${type}/update/${item?.id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoading());
  if (showLogs) {
    console.log(`/${type}/update/${item?.id}`, res);
  }
  return res;
};

export const checkDuplicateWorkOrder = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/checkDuplicateWorkOrder/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/checkDuplicateWorkOrder/${id}`, res);
  }
  if (res?.status) {
    return res?.data?.id;
  } else {
    return {};
  }
};

export const uploadTemplate = async (file, id) => {
  store.dispatch(setLoadingAdmin());
  const fetchData = new FormData();
  fetchData.append('file', file);
  fetchData.append('filename', file?.name);
  const data = await axios.post(`${apiUrl}/options/template/upload/${id}`, fetchData, {
    headers: axiosHeadersFormData,
  });
  const res = data?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/option/template/upload/${id}`, res);
  }
  if (res?.status) {
    let dispatchData = {};
    dispatchData.type = SET_OPTIONS;
    for (let i = 0; i < optionTypes.length; i++) {
      dispatchData[optionTypes[i]] = res?.data?.filter(c => c?.type === optionTypes[i]);
    }
    store.dispatch(dispatchData);
    return res?.data;
  } else {
    return {};
  }
};

export const getPaginatedObjectList = async (countPerPage, page, type) => {
  store.dispatch(setLoading());
  const data = await axios.post(`${apiUrl}/${type}/getAll?page=${page}&countPerPage=${countPerPage}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  store.dispatch(clearLoading());
  if (showLogs) {
    console.log(`/${type}/getAll?page=${page}&countPerPage=${countPerPage}`, res);
  }
  if (res?.status) {
    return res?.data?.original;
  } else {
    return {};
  }
};

// -----  RELATED --------

export const getRelatedOpportunities = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedOpportunity/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedOpportunity/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return [];
  }
};

export const getRelatedWorkOrders = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedWorkOrders/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedWorkOrders/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return [];
  }
};

export const getRelatedCases = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedCase/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedCase/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return [];
  }
};

export const getRelatedQuotes = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/QuotesByOpportunityID/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/QuotesByOpportunityID/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

export const getRelatedAssets = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedAssets/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedAssets/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

export const getRelatedMaintenancePlans = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedMaintenancePlans/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedMaintenancePlans/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

export const getRelatedServiceContracts = async (id, type) => {
  const data = await axios.post(`${apiUrl}/${type}/getRelatedServiceContracts/${id}`, {
    headers: axiosHeaders,
  });
  const res = data?.data;
  if (showLogs) {
    console.log(`/${type}/getRelatedServiceContracts/${id}`, res);
  }
  if (res?.status) {
    return res?.data;
  } else {
    return {};
  }
};

// -----  OTHER --------

export const getFakeData = async url => {
  store.dispatch(setLoading());
  const data = await axios.get(url, {
    headers: axiosHeaders,
  });
  store.dispatch(clearLoading());
  if (showLogs) {
    console.log(data?.data);
  }
  return data?.data;
};

export const getFakeDataNoLoader = async url => {
  const data = await axios.get(url, {
    headers: axiosHeaders,
  });
  if (showLogs) {
    console.log(data?.data);
  }
  return data?.data;
};

export const testApi = async (target = 'account') => {
  const data = {
    target,
  };

  if (showLogs) {
    console.log('export send Data :', data);
  }

  const fetchData = await axios.post(`${apiUrl}/export`, data, {
    headers: axiosHeaders,
  });

  if (showLogs) {
    console.log(fetchData?.data);
  }
  return fetchData?.data;
};
