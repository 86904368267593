import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

const WorkTypeForm = ({ duration }) => {
  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Work Type Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'duration',
      label: 'Estimated Duration',
      type: 'number',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'type',
      label: 'Duration Type',
      type: 'text',
      component: 'select',
      required: '1',
      options: duration,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'autoCreate',
      label: 'Auto-Create Service Appoinment',
      type: 'checkbox',
      component: 'input',
      defaultValue: 1,
    },
  ];

  return (
    <>
      <div className='row m-0'>
        {fields
          ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
          .map(item => (
            <div
              className='col-12 col-md-6'
              key={item?.id}
            >
              <div className='mt-3'>
                <label className='form-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-control'
                  type={item?.type}
                  required={item?.required === '1'}
                />
              </div>
            </div>
          ))}

        {fields
          ?.filter(i => i.component === 'select')
          .map(item => (
            <div
              className='col-12 col-md-6 my-3 mx-auto'
              key={item?.id}
            >
              <label className='form-label'>{item?.label}</label>
              <Field
                name={item?.name}
                component='select'
                className='form-control'
                required={item?.required === '1'}
              >
                <option
                  value=''
                  key={0}
                >
                  Select
                </option>
                {item?.options?.map(dur => (
                  <option
                    value={dur?.id}
                    key={dur?.id}
                  >
                    {dur?.name}
                  </option>
                ))}
              </Field>
            </div>
          ))}

        <div className='form-check col-12 col-md-6 px-5 py-3 d-flex justify-content-between'>
          {fields
            ?.filter(i => i.type === 'checkbox')
            .map(item => (
              <div key={item?.id}>
                <label className='form-check-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-check-input'
                  type={item?.type}
                  required={item?.required === '1'}
                  defaultValue={item?.defaultValue}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    duration: state?.constants?.units?.['duration'],
  };
};

export default connect(mapStateToProps)(WorkTypeForm);
