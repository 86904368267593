import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import TableActions from 'components/shared/table/TableActions';
import { deleteHolidayGroupById, editHolidayGroup } from 'utils/functions/admin/holidayGroup-func';
import ModalHolidayGroup from './ModalHolidayGroup';

const HolidayGroupsList = ({ holidayGroups }) => {
  const [stagedItem, setStagedItem] = useState({});
  const maxDayShow = 4;

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(holidayGroups?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(holidayGroups?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteHolidayGroupById(stagedItem?.id)}
      />

      <ModalHolidayGroup
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name} Holiday Group`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editHolidayGroup(values, stagedItem?.id)}
      />

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Holiday Group Name</th>
              <th>Days</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {holidayGroups?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>
                  {JSON.parse(item?.days || '[]')
                    ?.slice(0, maxDayShow)
                    ?.map((item, i) => (
                      <span key={item?.id}>
                        {' '}
                        {item?.name} {i + 1 < maxDayShow ? '-' : '- . . .'}
                      </span>
                    ))}
                </td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default HolidayGroupsList;
