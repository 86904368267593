import {
  SET_PRIMARY_TABS,
  SET_PRIMARY_ACTIVE_TAB,
  SET_PRIMARY_LAST_TAB,
  SET_MAIN_ITEM_ACTIVE,
  LOG_OUT,
} from '../actionTypes';

const initialState = {
  primary: [
    {
      id: 1,
      title: 'Main',
      type: 'main',
    },
  ],
  primaryActive: {
    id: 1,
    title: 'Home',
    type: 'home',
    hasClose: false,
    hasDrop: true,
  },
  mainItems: [
    {
      id: 1,
      name: 'home',
      label: 'Home',
      img: '/assets/img/icons/home.png',
    },
    {
      id: 2,
      name: 'account',
      label: 'Accounts',
      img: '/assets/img/icons/account.png',
    },
    {
      id: 3,
      name: 'contact',
      label: 'Contacts',
      img: '/assets/img/icons/contact.png',
    },
    // {
    // 	id: 4,
    // 	name: 'task',
    // 	label: 'Task',
    // 	img: '/assets/img/icons/task.png',
    // },
    {
      id: 5,
      name: 'opportunity',
      label: 'Opportunity',
      img: '/assets/img/icons/opportunity.png',
    },
    {
      id: 6,
      name: 'quote',
      label: 'Quotes',
      img: '/assets/img/icons/quote.png',
    },
    {
      id: 12,
      name: 'maintenance_plan',
      label: 'Maintenance Plans',
      img: '/assets/img/icons/maintenance_plan.png',
    },
    {
      id: 7,
      name: 'work_order',
      label: 'Work Orders',
      img: '/assets/img/icons/work_order.png',
    },
    {
      id: 8,
      name: 'service_contract',
      label: 'Service Contracts',
      img: '/assets/img/icons/service_contract.png',
    },
    {
      id: 9,
      name: 'case',
      label: 'Cases',
      img: '/assets/img/icons/case.png',
    },
    {
      id: 10,
      name: 'pricebook',
      label: 'Pricebooks',
      img: '/assets/img/icons/pricebook.png',
    },
    {
      id: 11,
      name: 'product',
      label: 'Products',
      img: '/assets/img/icons/product.png',
    },
    {
      id: 13,
      name: 'service_resource',
      label: 'Service Resources',
      img: '/assets/img/icons/service_resource.png',
    },
    {
      id: 14,
      name: 'service_appointment',
      label: 'Service Appointment',
      img: '/assets/img/icons/service_appointment.png',
    },
  ],
  mainItemActive: {
    id: 2,
    name: 'account',
    label: 'Accounts',
    img: '/assets/img/icons/account.png',
  },
};

const tabsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_PRIMARY_TABS:
      return {
        ...state,
        primary: action?.tabs,
      };
    case SET_PRIMARY_ACTIVE_TAB:
      return {
        ...state,
        primaryActive: action?.tab,
      };
    case SET_PRIMARY_LAST_TAB:
      return {
        ...state,
        primaryLast: action?.tab,
      };
    case SET_MAIN_ITEM_ACTIVE:
      return {
        ...state,
        mainItemActive: action?.item,
      };
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default tabsReducer;
