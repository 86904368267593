import React, { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import MainLoader from '../components/shared/loader/MainLoader';
import PrivateRoutes from './PrivateRoutes';
import { Toaster } from 'react-hot-toast';

import Login from '../components/pages/login/Login';

import HomeAdmin from '../components/pages/admin/HomeAdmin';
import AdminRoutes from './AdminRoutes';
import Skills from 'components/pages/admin/service-resources/skills/Skills';
import WorkTypes from 'components/pages/admin/service-resources/work-types/WorkTypes';
import SchedulingConstraints from 'components/pages/admin/service-resources/scheduling-constraint/SchedulingConstraints';
import Levels from 'components/pages/admin/service-resources/levels/Levels';
import Categories from 'components/pages/admin/classifications/categories/Categories';
import Units from 'components/pages/admin/classifications/units/Units';
import Roles from 'components/pages/admin/users/roles/Roles';
import UsersList from 'components/pages/admin/users/usersList/UsersList';
import Territories from 'components/pages/admin/service-resources/territories/Territories';
import OperatingHours from 'components/pages/admin/service-resources/operating-hours/OperatingHours';
import Options from 'components/pages/admin/settings/options/Options';
import TaxGroups from 'components/pages/admin/classifications/taxt-groups/TaxGroups';
import AssetTypes from 'components/pages/admin/classifications/asset-types/AssetTypes';
import HolidayGroups from 'components/pages/admin/classifications/holiday-groups/HolidayGroups';
import Fieldsets from 'components/pages/admin/fieldsets/Fieldsets';
import WoGeneration from 'components/pages/admin/settings/wo-generation/WoGeneration';
import Templates from 'components/pages/admin/settings/templates/Templates';

// import Dashboard from '../components/pages/dashboard/Dashboard';
const Dashboard = lazy(() => import('../components/pages/dashboard/Dashboard'));

const Router = () => {
  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          duration: 5000,
        }}
      />
      <main>
        <Suspense fallback={<MainLoader lazyLoading />}>
          <MainLoader />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                exact
                path='/dashboard'
                element={<Dashboard />}
              />
              <Route
                exact
                path='/admin'
                element={<AdminRoutes />}
              >
                <Route
                  exact
                  path='/admin'
                  element={<HomeAdmin />}
                />
                <Route
                  path='/admin/users'
                  element={<Outlet />}
                >
                  <Route
                    path='/admin/users/list'
                    element={<UsersList />}
                  />
                  <Route
                    path='/admin/users/role'
                    element={<Roles />}
                  />
                  <Route
                    path='/admin/users/account-industry'
                    element={
                      <Categories
                        type='account-industry'
                        img='industry'
                        name='Account Industry'
                      />
                    }
                  />
                  <Route
                    path='/admin/users/salutation'
                    element={
                      <Categories
                        type='salutation'
                        img='salutation'
                        name='Salutation'
                      />
                    }
                  />
                  <Route
                    path='/admin/users/contact-position'
                    element={
                      <Categories
                        type='contact-position'
                        img='contact_position'
                        name='Contact Position'
                      />
                    }
                  />
                </Route>
                <Route
                  path='/admin/service-resources'
                  element={<Outlet />}
                >
                  <Route
                    path='/admin/service-resources/skills'
                    element={<Skills />}
                  />
                  <Route
                    path='/admin/service-resources/levels'
                    element={<Levels />}
                  />
                  <Route
                    path='/admin/service-resources/resource-types'
                    element={
                      <Categories
                        type='resource-types'
                        img='service_crew'
                        name='Resource Types'
                      />
                    }
                  />
                  <Route
                    path='/admin/service-resources/territories'
                    element={<Territories />}
                  />
                  <Route
                    path='/admin/service-resources/resource-priority'
                    element={
                      <Categories
                        type='resource-priority'
                        img='resource_priority'
                        name='Resource Priority'
                      />
                    }
                  />
                  <Route
                    path='/admin/service-resources/work-types'
                    element={<WorkTypes />}
                  />
                  <Route
                    path='/admin/service-resources/scheduling-constraint'
                    element={<SchedulingConstraints />}
                  />
                  <Route
                    path='/admin/service-resources/operating-hours'
                    element={<OperatingHours />}
                  />
                </Route>
                <Route
                  path='/admin/classifications/'
                  element={<Outlet />}
                >
                  <Route
                    path='/admin/classifications/holiday-group'
                    element={<HolidayGroups />}
                  />
                  <Route
                    path='/admin/classifications/tax-group'
                    element={<TaxGroups />}
                  />
                  <Route
                    path='/admin/classifications/product-categories'
                    element={
                      <Categories
                        type='product'
                        img='product'
                        name='Product Categories'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/asset-property-type'
                    element={
                      <Categories
                        type='asset-property-type'
                        img='asset_property_type'
                        name='Asset Property Type'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/asset-types'
                    element={<AssetTypes />}
                  />
                  <Route
                    path='/admin/classifications/asset-properties'
                    element={
                      <Categories
                        type='asset-property'
                        img='asset_action'
                        name='Asset Properties'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/case-status'
                    element={
                      <Categories
                        type='case-status'
                        img='case_status'
                        name='Case Status'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/case-reason'
                    element={
                      <Categories
                        type='case-reason'
                        img='case_reason'
                        name='Case Reason'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/case-priority'
                    element={
                      <Categories
                        type='case-priority'
                        img='case_priority'
                        name='Case Priority'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/case-type'
                    element={
                      <Categories
                        type='case-type'
                        img='case_type'
                        name='Case Type'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/work_order-type'
                    element={
                      <Categories
                        type='work_order-type'
                        img='work_order_type'
                        name='Work Order Type'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/opportunity-loss'
                    element={
                      <Categories
                        type='opportunity-loss'
                        img='opportunity_loss'
                        name='Opportunity Loss Reasons'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/contract-type'
                    element={
                      <Categories
                        type='contract-type'
                        img='contract_type'
                        name='Contract Type'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/currency'
                    element={
                      <Units
                        type='currency'
                        img='currency'
                        name='Currencies'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/opportunity-type'
                    element={
                      <Categories
                        type='opportunity-type'
                        img='opportunity'
                        name='Opportunity Type'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/measurement'
                    element={
                      <Units
                        type='measurement'
                        img='dashboard'
                        name='Measurement'
                      />
                    }
                  />
                  <Route
                    path='/admin/classifications/duration'
                    element={
                      <Units
                        type='duration'
                        img='business_hours'
                        name='Durations'
                      />
                    }
                  />
                </Route>
                <Route
                  path='/admin/fieldset/'
                  element={<Outlet />}
                >
                  <Route
                    path='/admin/fieldset/inspection'
                    element={
                      <Fieldsets
                        object='inspection'
                        img='inspection'
                      />
                    }
                  />
                </Route>
                <Route
                  path='/admin/settings/'
                  element={<Outlet />}
                >
                  <Route
                    path='/admin/settings/tokens'
                    element={
                      <Options
                        type='token'
                        img='tokens'
                        name='Token'
                      />
                    }
                  />
                  <Route
                    path='/admin/settings/wo-generation'
                    element={<WoGeneration />}
                  />
                  <Route
                    path='/admin/settings/templates'
                    element={<Templates />}
                  />
                </Route>
              </Route>
            </Route>
            <Route
              exact
              path='/'
              element={<Login />}
            />
          </Routes>
        </Suspense>
      </main>
    </>
  );
};

export default Router;
