import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LOG_OUT } from 'redux/actionTypes';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch({ type: LOG_OUT });
    navigate('/');
    window?.location?.reload();
  };
  return (
    <section className='error_page'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 '>
            <div className='col-sm-10 col-sm-offset-1 mx-auto text-center'>
              <h1 className='text-center mb-4'>Somthing went wrong ...</h1>
              <div className='four_zero_four_bg'></div>

              <div className='contant_box_404'>
                <h3 className='my-5'>{error?.message}</h3>

                <button
                  className='btn_error_page rounded border-0 d-block mx-auto'
                  onClick={resetErrorBoundary}
                >
                  Reload Page
                </button>
                <button
                  className='btn btn_outline_success rounded border-0'
                  onClick={() => handleLogOut()}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorFallback;
