import React, { useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

const AdminHeader = ({ onUpdate, onAddNew, img, name, showSearch = true }) => {
  useEffect(() => {
    onUpdate();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='admin_header d-flex align-items-center justify-content-between px-3 py-2'>
        <div className='d-flex align-items-center'>
          <img
            alt=''
            className='me-3'
            src={`${process.env.PUBLIC_URL}/assets/img/icons/${img}.png`}
          />
          <span className='d-flex flex-column'>
            <h4 className='mb-0 text-capitalize'>{name}</h4>
          </span>
        </div>
        <div className='d-flex align-items-center'>
          {showSearch ? (
            <div className='tab_search input-group mx-auto'>
              <button
                className='input-group-text border-end-0'
                id={`tab-search-${name}`}
                onClick={() => alert('')}
              >
                <BsSearch />
              </button>
              <input
                type='text'
                className='form-control form-control-sm py-1 border-start-0'
                placeholder={`Search in ${name} . . .`}
                aria-describedby={`tab-search-${name}`}
              />
            </div>
          ) : (
            ''
          )}
          {onUpdate ? (
            <button
              type='button'
              className='bg-transparent border-0'
              title='Update Data'
              onClick={() => onUpdate()}
            >
              <img
                alt=''
                className='icon me-2'
                src={`${process.env.PUBLIC_URL}/assets/img/icons/refresh.png`}
              />
            </button>
          ) : (
            ''
          )}
          <ButtonGroup size='sm'>
            {onAddNew ? (
              <Button
                variant='outline-success'
                className='border text-capitalize'
                onClick={onAddNew}
              >
                <AiOutlinePlus
                  size={16}
                  className='me-1'
                />
                New
              </Button>
            ) : (
              ''
            )}
          </ButtonGroup>
        </div>
      </div>
      <hr className='my-2' />
    </>
  );
};

export default AdminHeader;
