import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

export const permissionRules = [
  {
    name: 'create',
    label: 'CREATE',
  },
  {
    name: 'modify',
    label: 'MODIFY',
  },
  {
    name: 'view',
    label: 'VIEW',
  },
  {
    name: 'delete',
    label: 'DELETE',
  },
  {
    name: 'view_others',
    label: "VIEW OTHERS'",
  },
  {
    name: 'task',
    label: 'Task',
  },
  {
    name: 'case',
    label: 'Case',
  },
  {
    name: 'account',
    label: 'Account',
  },
  {
    name: 'view_account',
    label: 'View Account',
  },
  {
    name: 'asset',
    label: 'Asset',
  },
  {
    name: 'view_asset',
    label: 'View Asset',
  },
  {
    name: 'contact',
    label: 'Contact',
  },
  {
    name: 'view_contact',
    label: 'View Contact',
  },
  {
    name: 'edit_contact',
    label: 'Edit Contact',
  },
  {
    name: 'Opportunity',
    label: 'Opportunity',
  },
  {
    name: 'quote',
    label: 'Quote',
  },
  {
    name: 'view_quote',
    label: 'View Quote',
  },
  {
    name: 'work_order',
    label: 'Work Order',
  },
  {
    name: 'view_work_order',
    label: 'View Work Order',
  },
  {
    name: 'edit_work_order',
    label: 'Edit Work Order',
  },
  {
    name: 'service_appointment',
    label: 'Service Appointment',
  },
  {
    name: 'view_service_appointment',
    label: 'View Service Appointment',
  },
  {
    name: 'edit_service_appointment',
    label: 'Edit Service Appointment',
  },
  {
    name: 'work_order_line_item_price_field',
    label: 'Work Order Line Item Price Field',
  },
  {
    name: 'view_work_order_line_item_price_field',
    label: 'View Work Order Line Item Price Field',
  },
  {
    name: 'work_order_line_item_cost_field',
    label: 'Work Order Line Item Cost Field',
  },
  {
    name: 'view_work_order_line_item_cost_field',
    label: 'View Work Order Line Item Cost Field',
  },
  {
    name: 'service_contract',
    label: 'Service Contract',
  },
  {
    name: 'view_service_contract',
    label: 'View Service Contract',
  },
  {
    name: 'service_contract_price_field',
    label: 'Service Contract Price Field',
  },
  {
    name: 'view_service_contract_price_field',
    label: 'View Service Contract Price Field',
  },
  {
    name: 'service_contract_cost_field',
    label: 'Service Contract Cost Field',
  },
  {
    name: 'service_contract_margin_field',
    label: 'Service Contract Margin Field',
  },
  {
    name: 'resource',
    label: 'Resource',
  },
  {
    name: 'view_resource',
    label: 'View Resource',
  },
  {
    name: 'region',
    label: 'Region',
  },
  {
    name: 'orders',
    label: 'Orders',
  },
  {
    name: 'products',
    label: 'Products',
  },
  {
    name: 'view_products',
    label: 'View Products',
  },
  {
    name: 'product_cost_field',
    label: 'Product Cost Field',
  },
  {
    name: 'view_product_cost_field',
    label: 'View Product Cost Field',
  },
  {
    name: 'product_landing_cost_fields',
    label: 'Product Landing Cost Fields',
  },
  {
    name: 'view_product_landing_cost_fields',
    label: 'View Product Landing Cost Fields',
  },
  {
    name: 'admin_settings',
    label: 'ADMIN SETTINGS',
  },
];

const ModalRole = ({ modal, toggle, onConfirm, initialValues, confirmBtnText, title }) => {
  const fields = {
    general: [
      {
        id: uuid()?.slice(0, 6),
        name: 'name',
        label: 'Role Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 6),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 6),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
    ],
    permissions: permissionRules?.map(item => ({
      id: uuid()?.slice(0, 8),
      name: item?.name,
      label: item?.label,
      type: 'checkbox',
      component: 'input',
      defaultValue: 0,
    })),
  };

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
          className='modal_w-100'
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-center w-100 border-bottom pb-1'>{title}</Modal.Title>
          </Modal.Header>

          <Form
            initialValues={initialValues}
            onSubmit={values => {
              onConfirm(values);
              toggle();
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className='row m-0'>
                    {fields?.general
                      ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
                      .map(item => (
                        <div
                          className='col-12 col-md-3'
                          key={item?.id}
                        >
                          <div className='mt-0'>
                            <label className='form-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-control'
                              type={item?.type}
                              required={item?.required === '1'}
                            />
                          </div>
                        </div>
                      ))}

                    {fields?.general
                      ?.filter(i => i.component === 'textarea')
                      .map(item => (
                        <div
                          className='col-6 mt-0'
                          key={item?.id}
                        >
                          <label className='form-label'>{item?.label}</label>
                          <Field
                            name={item?.name}
                            component='textarea'
                            className='form-control'
                            required={item?.required === '1'}
                            rows='1'
                          />
                        </div>
                      ))}

                    <div className='form-check col-12 col-md-3 py-4 mt-3 px-5 d-flex justify-content-between'>
                      {fields?.general
                        ?.filter(i => i.type === 'checkbox')
                        .map(item => (
                          <div key={item?.id}>
                            <label className='form-check-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-check-input'
                              type={item?.type}
                              required={item?.required === '1'}
                              defaultValue={item?.defaultValue}
                            />
                          </div>
                        ))}
                    </div>

                    <hr className='my-2' />

                    {fields?.permissions
                      ?.filter(i => i.type === 'checkbox')
                      .map(item => (
                        <div className='form-check col-12 col-md-3 pt-3 px-5 d-flex justify-content-between'>
                          <div key={item?.name}>
                            <label className='form-check-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-check-input'
                              type={item?.type}
                              required={item?.required === '1'}
                              defaultValue={item?.defaultValue}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-center w-100 mt-3'>
                  <Button
                    className='mx-auto'
                    variant='secondary'
                    onClick={() => toggle(false)}
                    style={{ width: '80px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='mx-auto'
                    variant='success'
                    type='submit'
                    style={{ width: '110px' }}
                  >
                    {confirmBtnText}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ModalRole);
