import MapViewer from 'components/shared/map/MapViewer';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiFillPlusSquare, AiOutlineQuestion } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { MdNotificationsNone } from 'react-icons/md';
// import { FcCalendar } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LOG_OUT } from 'redux/actionTypes';
import { onAddTab } from 'utils/functions/tabs';
import ModalCalender from './components/ModalCalender';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogOut = () => {
    dispatch({ type: LOG_OUT });
    navigate('/');
  };

  const plusItems = [
    {
      id: 1,
      label: 'New Account',
      type: 'new-account',
      img: '/assets/img/icons/account.png',
    },
    {
      id: 2,
      label: 'New Contact',
      type: 'new-contact',
      img: '/assets/img/icons/contact.png',
    },
    {
      id: 3,
      label: 'New Case',
      type: 'new-case',
      img: '/assets/img/icons/case.png',
    },
    {
      id: 4,
      label: 'New Opportunity',
      type: 'new-opportunity',
      img: '/assets/img/icons/opportunity.png',
    },
    {
      id: 5,
      label: 'New Work Order',
      type: 'new-work_order',
      img: '/assets/img/icons/work_order.png',
    },
    {
      id: 6,
      label: 'New Contract',
      type: 'new-contract',
      img: '/assets/img/icons/service_contract.png',
    },
  ];

  const [modalCalender, setModalCalender] = useState(false);
  const toggleCalender = () => {
    setModalCalender(prevState => !prevState);
  };

  return (
    <>
      {/* This is for fix the bug of Google is not defined */}
      {location?.pathname === '/dashboard' ? (
        <div className='position-fixed'>
          <MapViewer
            object={{ mailing_latitude: 45, mailing_longitude: -75 }}
            type='mailing'
          />
        </div>
      ) : (
        ''
      )}

      <ModalCalender
        modal={modalCalender}
        toggle={toggleCalender}
      />

      <div className='main_header d-flex justify-content-between align-items-center p-2 pt-3'>
        <Link to='/dashboard'>
          <img
            alt=''
            className='logo_dashboard'
            src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}
          />
        </Link>
        <div
          className='header_search input-group mx-auto'
          style={{ opacity: 0 }}
        >
          <button
            className='input-group-text border-end-0'
            id='header_search'
          >
            <BsSearch />
          </button>
          <input
            type='text'
            className='form-control py-1 border-start-0'
            placeholder='Search . . .'
            aria-describedby='header_search'
          />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          {/* <button
            className='header_icon bg-transparent border-0 me-2'
            type='button'
            title='Google Calender'
            onClick={() => setModalCalender(true)}
          >
            <FcCalendar
              size={24}
              fill='#adadad'
            />
          </button> */}
          <Dropdown className='header-dropdown'>
            <Dropdown.Toggle
              variant=''
              className='p-0'
            >
              <AiFillPlusSquare
                size={24}
                fill='#adadad'
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {plusItems?.map(item => (
                <Dropdown.Item
                  key={item?.id}
                  onClick={() => onAddTab(item?.label, item?.type)}
                  className='d-flex align-items-center'
                >
                  <img
                    alt=''
                    className='me-2'
                    src={`${process.env.PUBLIC_URL}${item?.img}`}
                  />
                  <span>{item?.label}</span>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <button
            className='header_icon bg-transparent border-0'
            type='button'
          >
            <AiOutlineQuestion
              size={24}
              fill='#adadad'
            />
          </button>
          <button
            className='header_icon bg-transparent border-0'
            type='button'
          >
            <FiSettings
              size={20}
              stroke='#adadad'
            />
          </button>
          <button
            className='header_icon bg-transparent border-0 me-2'
            type='button'
          >
            <MdNotificationsNone
              size={24}
              fill='#adadad'
            />
          </button>

          <Dropdown className='header-dropdown'>
            <Dropdown.Toggle
              variant=''
              className='p-0'
            >
              <img
                alt=''
                className='avatar mt-5 mt-md-0 rounded-circle'
                src={`${process.env.PUBLIC_URL}/assets/img/avatar.png`}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='/dashboard'>Profile</Dropdown.Item>
              <Dropdown.Item href='/dashboard'>Settings</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleLogOut()}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <hr className='w-100 m-0' />
      {/* <button
        type='button'
        className='btn'
        onClick={() => dispatch(getAllConstants())}
      >
        REEEEEQ{' '}
      </button> */}
    </>
  );
};

export default Header;
