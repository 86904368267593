import React, { useEffect, useState } from 'react';
import ModalApp from '../Modal/ModalApp';
import { useLocation } from 'react-router';

const AppTap = () => {
  const location = useLocation();

  const [appName, setAppName] = useState('SUPERIOR');

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(prevState => !prevState);
  };

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setAppName('SUPERIOR');
    } else {
      setAppName('ADMINISTRATION');
    }
  }, [location]);

  return (
    <>
      <ModalApp
        modal={modal}
        toggle={toggle}
      />
      <button
        type='button'
        className='appTab bg-transparent border-0 px-3 text-start d-inline-flex align-items-center'
        onClick={() => toggle()}
      >
        <img
          alt=''
          className='me-2'
          src={`${process.env.PUBLIC_URL}/assets/img/icons/apps.png`}
        />
        <span className='fw-bold'>{appName}</span>
      </button>
    </>
  );
};

export default AppTap;
