import classNames from 'classnames';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-final-form';

const ModalForm = ({
  title,
  children,
  modal,
  toggle,
  onConfirm,
  confirmColor,
  confirmBtnText,
  initialValues,
  isLarge,
}) => {
  return (
    <div>
      <Modal
        show={modal}
        onHide={toggle}
        className={classNames('modal_form', { modal_lg: isLarge })}
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center w-100'>{title}</Modal.Title>
        </Modal.Header>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            onConfirm(values);
            toggle();
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>{children}</Modal.Body>
              <Modal.Footer className='text-center w-100'>
                <Button
                  className='mx-auto'
                  variant='secondary'
                  onClick={() => toggle(false)}
                  style={{ width: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  className='mx-auto'
                  variant={confirmColor || 'success'}
                  type='submit'
                  style={{ width: '130px' }}
                >
                  {confirmBtnText || 'Ok'}
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal>
    </div>
  );
};

export default ModalForm;
