import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { axiosHeaders } from 'utils/axiosHeaders';
import { SET_SCHEDULING_CONSTRAINTS } from 'redux/actionTypes';

export const addSchedulingConstraints = async values => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };
  if (showLogs) {
    console.log('addSchedulingConstraints : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/scheduling/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/scheduling/add ', res);
  }
  if (res?.status) {
    toastSuccess(`The ${values?.name} has been added successfully!`);
    store.dispatch({
      type: SET_SCHEDULING_CONSTRAINTS,
      schedulingConstraints: res?.data,
    });
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editSchedulingConstraints = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editSchedulingConstraints', data);
  }

  const fetchData = await axios.post(`${apiUrl}/scheduling/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/scheduling/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_SCHEDULING_CONSTRAINTS,
      schedulingConstraints: res?.data,
    });
    toastSuccess(`The ${values?.name} has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteSchedulingConstraintsById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/scheduling/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/scheduling/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_SCHEDULING_CONSTRAINTS,
      schedulingConstraints: res?.data,
    });
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
