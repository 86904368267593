import React from 'react';
import { connect } from 'react-redux';

const HomeAdmin = () => {
  return (
    <>
      <div className='p-3'>
        <h2>Home Admin content</h2>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    tabs: state?.tabs?.primary,
  };
};

export default connect(mapStateToProps)(HomeAdmin);
