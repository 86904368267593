import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiEdit2Line } from 'react-icons/ri';
import { RxCross1 } from 'react-icons/rx';
import { editOption } from 'utils/functions/admin/options-func';

const OptionInput = ({ option }) => {
  const [value, setValue] = useState(option?.value);
  const [editMode, setEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  return (
    <div className='mt-3'>
      <label className='form-label'>{option?.name}</label>
      <div
        className='input-group mb-3'
        onMouseLeave={() => {
          if (!editMode) {
            setShowEditButton(false);
          }
        }}
      >
        <input
          name={option?.name}
          className='form-control'
          value={value}
          onChange={e => setValue(e?.target?.value)}
          disabled={!editMode}
          onMouseOver={() => setShowEditButton(true)}
          onKeyDown={e => {
            if (e?.key === 'Enter') {
              setEditMode(false);
              editOption({ ...option, value }, option?.id);
            }
          }}
        />
        {editMode ? (
          <>
            <button
              type='button'
              title='Edit'
              className='bg-transparent input-group-text px-3'
              onClick={() => setEditMode(false)}
            >
              <RxCross1
                color='red'
                size={18}
              />
            </button>
            <button
              type='button'
              title='Edit'
              className='bg-transparent input-group-text px-3'
              onClick={() => {
                setEditMode(false);
                editOption({ ...option, value }, option?.id);
              }}
            >
              <AiOutlineCheck
                size={18}
                fill='green'
                className='me-1'
              />
            </button>
          </>
        ) : (
          ''
        )}

        {!editMode && showEditButton ? (
          <button
            type='button'
            title='Edit'
            className='bg-transparent input-group-text px-3 text-center'
            onClick={() => setEditMode(true)}
            style={{ width: '50px' }}
          >
            <RiEdit2Line
              size={18}
              fill='gray'
              className='mx-auto'
            />
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default OptionInput;
