import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalT = ({
	title,
	message,
	modal,
	toggle,
	onConfirm,
	confirmColor,
	confirmBtnText,
}) => {
	return (
		<div>
			<Modal show={modal} onHide={toggle}>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{message}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => toggle(false)}>
						Cancel
					</Button>
					<Button
						variant={confirmColor || 'success'}
						onClick={() => {
							onConfirm();
							toggle();
						}}
					>
						{confirmBtnText || 'Ok'}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ModalT;
