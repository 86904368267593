import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

const ModalAssetTypes = ({
  modal,
  toggle,
  onConfirm,
  initialValues,
  confirmBtnText,
  title,
  inspectionFields,
}) => {
  const fields = {
    general: [
      {
        id: uuid()?.slice(0, 6),
        name: 'name',
        label: 'Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 6),
        name: 'slug',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 6),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
    ],
    generalField: inspectionFields
      ?.filter(i => i.class === 'General')
      ?.map(item => ({
        id: item?.id,
        name: item?.uniqueKey,
        label: item?.label,
        type: 'checkbox',
        component: 'input',
        defaultValue: false,
      })),
    partialField: inspectionFields
      ?.filter(i => i.class === 'Partial')
      ?.map(item => ({
        id: item?.id,
        name: item?.uniqueKey,
        label: item?.label,
        type: 'checkbox',
        component: 'input',
        defaultValue: false,
      })),
  };

  const onSubmit = async values => {
    const selection = {};
    for (let i = 0; i < inspectionFields.length; i++) {
      selection[inspectionFields[i]?.uniqueKey] = values[inspectionFields[i]?.uniqueKey];
    }

    const info = [];
    const selectionKeys = Object.keys(selection || {});
    for (let i = 0; i < selectionKeys?.length; i++) {
      const field = inspectionFields?.find(f => f.uniqueKey === selectionKeys?.[i]);
      info.push({
        id: field?.id,
        type: field?.type,
        uniqueKey: field?.uniqueKey,
        options: field?.options,
        label: field?.label,
        placeholder: field?.placeholder,
        required: field?.required,
        hidden: field?.hidden,
        validator: field?.validator,
        class: field?.class,
        selected: values[selectionKeys?.[i]],
      });
    }

    for (let i = 0; i < inspectionFields.length; i++) {
      delete values[inspectionFields[i]?.uniqueKey];
    }
    const data = {
      ...values,
      info: JSON.stringify(info),
    };
    onConfirm(data);
  };

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
          className='modal_w-100'
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-center w-100 border-bottom pb-1'>{title}</Modal.Title>
          </Modal.Header>

          <Form
            initialValues={initialValues}
            onSubmit={async values => {
              await onSubmit(values);
              toggle();
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className='row m-0'>
                    {fields?.general
                      ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
                      .map(item => (
                        <div
                          className='col-12 col-md-3'
                          key={item?.id}
                        >
                          <div className='mt-0'>
                            <label className='form-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-control'
                              type={item?.type}
                              required={item?.required === '1'}
                            />
                          </div>
                        </div>
                      ))}

                    {fields?.general
                      ?.filter(i => i.component === 'textarea')
                      .map(item => (
                        <div
                          className='col-6 mt-0'
                          key={item?.id}
                        >
                          <label className='form-label'>{item?.label}</label>
                          <Field
                            name={item?.name}
                            component='textarea'
                            className='form-control'
                            required={item?.required === '1'}
                            rows='1'
                          />
                        </div>
                      ))}

                    <div className='form-check col-12 col-md-3 py-4 mt-3 px-5 d-flex justify-content-between'>
                      {fields?.general
                        ?.filter(i => i.type === 'checkbox')
                        .map(item => (
                          <div key={item?.id}>
                            <label className='form-check-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-check-input'
                              type={item?.type}
                              required={item?.required === '1'}
                              defaultValue={item?.defaultValue}
                            />
                          </div>
                        ))}
                    </div>

                    <hr className='mt-2 mb-4' />

                    {/* <h5 className='text-center my-3'>Required fields for Inspection :</h5> */}

                    <h5>General Fields : </h5>

                    {fields?.generalField.map(item => (
                      <div
                        key={item?.id}
                        className='form-check col-12 col-md-3 pt-3 px-5 d-flex justify-content-between'
                      >
                        <div>
                          <label className='form-check-label'>{item?.label}</label>
                          <Field
                            name={item?.name}
                            component='input'
                            className='form-check-input'
                            type='checkbox'
                            required={item?.required === '1'}
                            defaultValue={item?.defaultValue}
                          />
                        </div>
                      </div>
                    ))}

                    <hr className='my-4' />

                    <h5>Partial Fields : </h5>

                    {fields?.partialField.map(item => (
                      <div
                        key={item?.id}
                        className='form-check col-12 col-md-3 pt-3 px-5 d-flex justify-content-between'
                      >
                        <div>
                          <label className='form-check-label'>{item?.label}</label>
                          <Field
                            name={item?.name}
                            component='input'
                            className='form-check-input'
                            type='checkbox'
                            required={item?.required === '1'}
                            defaultValue={item?.defaultValue}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-center w-100 mt-3'>
                  <Button
                    className='mx-auto'
                    variant='secondary'
                    onClick={() => toggle(false)}
                    style={{ width: '80px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='mx-auto'
                    variant='success'
                    type='submit'
                    style={{ width: '110px' }}
                  >
                    {confirmBtnText}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { inspectionFields: state?.constants?.fieldsets?.inspection?.filter(f => f.status === 1) };
};

export default connect(mapStateToProps)(ModalAssetTypes);
