import React from 'react';
import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';

const SkillForm = () => {
  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Skill Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
    },
  ];

  return (
    <>
      <div className='row m-0'>
        {fields
          ?.filter(i => i.component === 'input' && i.type === 'text')
          .map(item => (
            <div
              className='col-12 col-md-6'
              key={item?.id}
            >
              <div className='mt-3'>
                <label className='form-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-control'
                  type={item?.type}
                  required={item?.required === '1'}
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SkillForm;
