import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

const TerritoryForm = ({ allTerritories, constraints, operatingHours }) => {
  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Territory Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'parent_id',
      label: 'Parent Territory',
      component: 'select',
      options: allTerritories,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'scheduling_constraint_id',
      label: 'Scheduling Constraints',
      component: 'select',
      options: constraints,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'operating_hours_id',
      label: 'Operating Hours',
      component: 'select',
      options: operatingHours,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'status',
      label: 'Is Active',
      type: 'checkbox',
      component: 'input',
      defaultValue: 1,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
    },
  ];

  return (
    <>
      <div className='row m-0'>
        {fields
          ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
          .map(item => (
            <div
              className='col-12 col-md-6'
              key={item?.id}
            >
              <div className='mt-3'>
                <label className='form-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-control'
                  type={item?.type}
                  required={item?.required === '1'}
                />
              </div>
            </div>
          ))}

        {fields
          ?.filter(i => i.component === 'select')
          .map(item => (
            <div
              className='col-12 col-md-6 mt-3'
              key={item?.id}
            >
              <label className='form-label'>{item?.label}</label>
              <Field
                name={item?.name}
                component='select'
                className='form-control'
                required={item?.required === '1'}
              >
                <option value=' '>-- Select --</option>
                {item?.options?.map(opt => (
                  <option
                    value={opt?.id}
                    key={opt?.id}
                  >
                    {opt?.name}
                  </option>
                ))}
              </Field>
            </div>
          ))}

        <div className='form-check col-12 col-md-6 pt-5 px-5 d-flex justify-content-between'>
          {fields
            ?.filter(i => i.type === 'checkbox')
            .map(item => (
              <div key={item?.id}>
                <label className='form-check-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-check-input'
                  type={item?.type}
                  required={item?.required === '1'}
                  defaultValue={item?.defaultValue}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    allTerritories: state?.constants?.territories,
    constraints: state?.constants?.schedulingConstraints,
    operatingHours: state?.constants?.operatingHours,
  };
};

export default connect(mapStateToProps)(TerritoryForm);
