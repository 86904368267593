import React, { useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import TableActions from 'components/shared/table/TableActions';
import { deleteCategoryById, editCategory, setCategoryActivity } from 'utils/functions/admin/category-func';
import ModalAssetTypes from './ModalAssetTypes';
import { connect } from 'react-redux';

const AssetTypesList = ({ data, inspectionFields }) => {
  const [stagedItem, setStagedItem] = useState({});
  const [preparedData, setPreparedData] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(data?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = async id => {
    if (id) {
      let rawData = await data?.find(u => u.id === id);
      let info = JSON.parse(rawData?.info || '[]');
      for (let i = 0; i < inspectionFields?.length; i++) {
        rawData[inspectionFields[i]?.uniqueKey] = info?.find(
          f => f.uniqueKey === inspectionFields[i]?.uniqueKey
        )?.selected;
      }
      setPreparedData(rawData);
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteCategoryById(stagedItem?.id)}
      />

      <ModalAssetTypes
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${preparedData?.name}`}
        initialValues={preparedData}
        confirmBtnText='Save'
        onConfirm={values => editCategory(values, preparedData?.id)}
      />

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Activity</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{item?.slug}</td>
                <td>
                  <Form.Check
                    type='switch'
                    className='activity_switch'
                    label={`${item?.status === 1 ? 'Active' : 'Deactive'}`}
                    checked={item?.status === 1 ? 1 : 0}
                    onChange={() => setCategoryActivity(item?.id, item?.status === 0 ? 1 : 0)}
                  />
                </td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { inspectionFields: state?.constants?.fieldsets?.inspection?.filter(f => f.status === 1) };
};

export default connect(mapStateToProps)(AssetTypesList);
