import { v4 as uuid } from 'uuid';
import { store } from 'app/store';
import { addressFields } from 'components/shared/Modal/ModalAddress';

const radioOptionsYesNo = [
  {
    id: 1,
    label: 'Yes',
    value: '1',
  },
  {
    id: 2,
    label: 'No',
    value: '0',
  },
];
const radioOptionsCapacity = [
  {
    id: 1,
    label: '1/4',
    value: '1',
  },
  {
    id: 2,
    label: '1/2',
    value: '2',
  },
  {
    id: 2,
    label: '3/4',
    value: '3',
  },
  {
    id: 2,
    label: 'FULL',
    value: '4',
  },
];

export const accountFieldsModel = () => {
  const categories = store?.getState()?.constants?.categories;
  const taxGroups = store?.getState()?.constants?.taxGroups;
  const holidayGroups = store?.getState()?.constants?.holidayGroups;
  return {
    general: [
      {
        id: uuid()?.slice(0, 9),
        name: 'name',
        label: 'Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'email',
        label: 'Email',
        type: 'email',
        component: 'input',
        required: '1',
        validation: 'email',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'password',
        label: 'Password',
        type: 'password',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'website',
        label: 'Website',
        type: 'text',
        component: 'input',
        validation: 'url',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'industry',
        label: 'Industry',
        component: 'select',
        options: categories?.['account-industry'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'tax_id',
        label: 'Tax group',
        component: 'select',
        options: taxGroups,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'holiday_id',
        label: 'Holiday group',
        component: 'select',
        options: holidayGroups,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
    ],
    cantactInformations: [
      {
        id: uuid()?.slice(0, 9),
        name: 'phone',
        label: 'Phone',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'tel',
        label: 'Tel',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'fax',
        label: 'Fax',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
    ],
    address: {
      billing: addressFields?.billing,
      shipping: addressFields?.shipping,
      mailing: addressFields?.mailing,
    },
  };
};

export const contactFieldsModel = () => {
  const categories = store?.getState()?.constants?.categories;
  return {
    name: [
      {
        id: uuid()?.slice(0, 9),
        name: 'salutation',
        label: 'Salutation',
        component: 'select',
        options: categories?.['salutation'],
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'firstname',
        label: 'First Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'middlename',
        label: 'Middle Name (optional)',
        type: 'text',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'lastname',
        label: 'Last Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
    ],
    general: [
      {
        id: uuid()?.slice(0, 9),
        name: 'cellphone',
        label: 'Cell Phone',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'office_phone',
        label: 'Office Phone',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'fax',
        label: 'Fax',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'individual_id',
        label: 'Individual Id',
        type: 'text',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'email',
        label: 'Email',
        type: 'email',
        component: 'input',
        required: '1',
        validation: 'email',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'password',
        label: 'Password',
        type: 'password',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'website',
        label: 'Website',
        type: 'text',
        component: 'input',
        validation: 'url',
      },
      {
        id: uuid()?.slice(0, 4),
        name: 'type',
        label: 'Type',
        type: 'text',
        component: 'select',
        options: [
          { id: 1, name: 'Primary', value: 'primary' },
          { id: 2, name: 'Secondary', value: 'secondary' },
        ],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'position',
        label: 'Position',
        type: 'text',
        component: 'select',
        options: categories?.['contact-position'],
      },

      {
        id: uuid()?.slice(0, 4),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'is_verified_email',
        label: 'Email Verified',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'is_decision_maker',
        label: 'Is Decision Maker',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'can_recieve_quote',
        label: 'Can Recieve Quotes',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'can_recieve_reports',
        label: 'Can Recieve Reports',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'can_recieve_invoice',
        label: 'Can Recieve Invoice',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
    ],
    address: {
      mailing: addressFields?.mailing,
      billing: addressFields?.billing,
    },
  };
};

export const serviceContractFieldsModel = () => {
  return {
    general: [
      {
        id: uuid()?.slice(0, 9),
        name: 'name',
        label: 'Contract Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'discount',
        label: 'Discount (%)',
        type: 'number',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'tax_rate',
        label: 'Tax Rate (%)',
        type: 'number',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'term',
        label: 'Term (Months)',
        type: 'number',
        component: 'input',
        validation: 'numeric|min:1,num',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'stage',
        label: 'Stage',
        type: 'text',
        component: 'select',
        required: '1',
        hidden: true,
        options: [
          { id: 1, name: 'Preparation' },
          { id: 2, name: 'Proposal' },
          { id: 10, name: 'Activated' },
          { id: 11, name: 'Lost' },
          { id: 12, name: 'Expired' },
        ],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'activationDate',
        label: 'Activation Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'endDate',
        label: 'End Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
    ],
    address: {
      billing: addressFields?.billing,
      shipping: addressFields?.shipping,
      mailing: addressFields?.mailing,
    },
  };
};

export const maintenancePlanFieldsModel = () => {
  const duration = store?.getState()?.constants?.units?.duration;
  const workTypes = store?.getState()?.constants?.workTypes;

  return {
    general: [
      {
        id: uuid()?.slice(0, 9),
        name: 'name',
        label: 'Maintenance Plan Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'workType_id',
        label: 'Work Type',
        type: 'text',
        component: 'select',
        required: '1',
        options: workTypes,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'endDate',
        label: 'End Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
    ],
    generation: [
      {
        id: uuid()?.slice(0, 9),
        name: 'windowStart',
        label: 'Maintenance Window Start ( Days )',
        type: 'number',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'frequency',
        label: 'Frequency',
        type: 'number',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'generationTimeframe',
        label: 'Generation Timeframe',
        type: 'number',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'windowEnd',
        label: 'Maintenance Window End ( Days )',
        type: 'number',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'frequencyType',
        label: 'Frequency Type',
        type: 'text',
        component: 'select',
        required: '1',
        options: duration,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'generationTimeframeType',
        label: 'Generation Timeframe Type',
        type: 'text',
        component: 'select',
        required: '1',
        options: duration,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'generationMethod',
        label: 'Work Order Generation Method',
        type: 'text',
        component: 'select',
        options: [
          { id: 'One Work Order Per Assets', name: 'One Work Order Per Assets' },
          { id: 'One Work Order For All Assets', name: 'One Work Order For All Assets' },
        ],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'generationHorizon',
        label: 'Generation Horizon (Days)',
        type: 'number',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'firstDate',
        label: 'Date Of First WO',
        type: 'date',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'autoGenerate',
        label: 'Auto Generate Work Orders',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'generateUponCompletion',
        label: 'Generate New Batch upon Completion',
        type: 'checkbox',
        component: 'input',
        defaultValue: 0,
      },
    ],
    automatic: [],
    // {
    //   id: uuid()?.slice(0, 9),
    //   name: 'Description',
    //   label: 'Description',
    //   type: 'text',
    //   component: 'textarea',
    //   required: '1',
    // },
  };
};

export const caseFieldsModel = () => {
  const categories = store?.getState()?.constants?.categories;

  return {
    additional: [
      {
        id: uuid()?.slice(0, 9),
        name: 'status',
        label: 'Status',
        type: 'text',
        component: 'select',
        required: '1',
        options: categories?.['case-status'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'reason',
        label: 'Case Reason',
        type: 'text',
        component: 'select',
        required: '1',
        options: categories?.['case-reason'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'priority',
        label: 'Priority',
        type: 'text',
        component: 'select',
        required: '1',
        options: categories?.['case-priority'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'type',
        label: 'Type',
        type: 'text',
        component: 'select',
        required: '1',
        options: categories?.['case-type'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'origin',
        label: 'Case Origin',
        type: 'text',
        component: 'select',
        required: '1',
        options: [
          {
            id: 1,
            name: 'Web',
            value: 1,
            relatedField: [
              {
                id: uuid()?.slice(0, 9),
                name: 'webEmail',
                label: 'Web Email',
                type: 'email',
                component: 'input',
              },
              {
                id: uuid()?.slice(0, 9),
                name: 'webCompany',
                label: 'Web Company',
                type: 'text',
                component: 'input',
              },
              {
                id: uuid()?.slice(0, 9),
                name: 'webName',
                label: 'Web Name',
                type: 'text',
                component: 'input',
              },
              {
                id: uuid()?.slice(0, 9),
                name: 'webPhone',
                label: 'Web Phone',
                type: 'text',
                component: 'input',
              },
            ],
          },
          {
            id: 2,
            name: 'Phone',
            value: 2,
            relatedField: [
              {
                id: uuid()?.slice(0, 9),
                name: 'phoneNumber',
                label: 'Phone Number',
                type: 'text',
                component: 'input',
              },
              {
                id: uuid()?.slice(0, 9),
                name: 'countryCode',
                label: 'Country Code',
                type: 'text',
                component: 'input',
              },
            ],
          },
          {
            id: 3,
            name: 'Email',
            value: 3,
            relatedField: [
              {
                id: uuid()?.slice(0, 9),
                name: 'emailAddress',
                label: 'Email Address',
                type: 'email',
                component: 'input',
              },
            ],
          },
          {
            id: 4,
            name: 'Twitter',
            value: 4,
            relatedField: [
              {
                id: uuid()?.slice(0, 9),
                name: 'twitterUsername',
                label: 'Twitter Username',
                type: 'text',
                component: 'input',
              },
              {
                id: uuid()?.slice(0, 9),
                name: 'twitterLink',
                label: 'Twitter Link',
                type: 'text',
                component: 'input',
              },
            ],
          },
        ],
      },
    ],
    description: [
      {
        id: uuid()?.slice(0, 9),
        name: 'subject',
        label: 'Subject',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'internalComments',
        label: 'Internal Comments',
        type: 'text',
        component: 'textarea',
        required: '1',
      },
    ],
  };
};

export const opportunityFieldsModel = () => {
  const categories = store?.getState()?.constants?.categories;

  return {
    description: [
      {
        id: uuid()?.slice(0, 9),
        name: 'name',
        label: 'Opportunity Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'probability',
        label: 'Probability',
        type: 'slider',
        component: 'slider',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'checkbox',
        defaultValue: 1,
      },
    ],
    status: [
      {
        id: uuid()?.slice(0, 9),
        name: 'type',
        label: 'Type',
        type: 'text',
        component: 'select',
        options: categories?.['opportunity-type'],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'stage',
        label: 'Stage',
        type: 'text',
        component: 'select',
        required: '1',
        options: [
          { id: 1, name: 'Qualification' },
          { id: 2, name: 'Need Analysis' },
          { id: 3, name: 'Proposal' },
          { id: 4, name: 'Negotiation' },
          { id: 10, name: 'Closed Won' },
          { id: 11, name: 'Closed Lost' },
        ],
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'CloseDate',
        label: 'Close Date',
        type: 'date',
        component: 'input',
        required: '1',
      },
    ],
    loss: [
      {
        id: uuid()?.slice(0, 9),
        name: 'lossReason',
        label: 'Loss Reason',
        type: 'text',
        component: 'select',
        options: categories?.['opportunity-loss'],
      },
    ],
  };
};

export const quoteFieldsModel = () => {
  return {
    information: [
      {
        id: uuid()?.slice(0, 9),
        name: 'name',
        label: 'Quote Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'description',
        label: 'Description',
        type: 'text',
        component: 'textarea',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'ExpirationDate',
        label: 'Expiration Date',
        type: 'date',
        component: 'input',
      },
    ],
    finance: [
      {
        id: uuid()?.slice(0, 9),
        name: 'discount',
        label: 'Discount',
        type: 'text',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'tax_rate',
        label: 'Tax Rate (%)',
        type: 'text',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'staffing_hours',
        label: 'Staffing Hours',
        type: 'text',
        component: 'input',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'maintenance_num_visits',
        label: 'Maintenance Num Visits',
        type: 'text',
        component: 'input',
      },
    ],
    prepared: [
      {
        id: uuid()?.slice(0, 9),
        name: 'phone',
        label: 'Phone',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'email',
        label: 'Email',
        type: 'email',
        component: 'input',
        validation: 'email',
      },
      {
        id: uuid()?.slice(0, 9),
        name: 'fax',
        label: 'Fax',
        type: 'text',
        component: 'input',
        validation: 'phone',
      },
    ],
  };
};

export const workOrderFieldsModel = () => {
  const workOrderTypes = store?.getState()?.constants?.categories?.['work_order-type'];
  const workTypes = store?.getState()?.constants?.workTypes;
  return [
    {
      id: uuid()?.slice(0, 6),
      name: 'startDate',
      label: 'start Date',
      type: 'date',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'subject',
      label: 'Subject',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'workType_id',
      label: 'Work Type',
      type: 'text',
      component: 'select',
      required: '1',
      options: workTypes,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'type_id',
      label: 'Type',
      type: 'text',
      component: 'select',
      required: '1',
      options: workOrderTypes,
    },
    {
      id: uuid()?.slice(0, 9),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'textarea',
    },
    {
      id: uuid()?.slice(0, 9),
      name: 'stage',
      label: 'Stage',
      type: 'text',
      component: 'select',
      required: '1',
      hidden: true,
      options: [
        { id: 10, name: 'New' },
        { id: 20, name: 'Scheduled' },
        { id: 30, name: 'Dispatch' },
        { id: 40, name: 'In Progress' },
        { id: 50, name: 'On Hold' },
        { id: 60, name: 'RTC' },
        { id: 70, name: 'RTB' },
        { id: 100, name: 'Completed' },
        { id: 110, name: 'Invoiced' },
        { id: 120, name: 'Can Not Complete' },
        { id: 130, name: 'Cancel' },
      ],
    },
  ];
};

export const serviceAppointmentFieldsModel = () => {
  const duration = store?.getState()?.constants?.units?.duration;
  const workTypes = store?.getState()?.constants?.workTypes;
  return [
    {
      id: uuid()?.slice(0, 6),
      name: 'scheduledDate',
      label: 'Scheduled Date',
      type: 'date',
      component: 'input',
    },
    // {
    //   id: uuid()?.slice(0, 6),
    //   name: 'startTime',
    //   label: 'Start Time',
    //   type: 'datetime-local',
    //   component: 'input',
    // },
    // {
    //   id: uuid()?.slice(0, 6),
    //   name: 'endTime',
    //   label: 'End Time',
    //   type: 'datetime-local',
    //   component: 'input',
    // },
    // {
    //   id: uuid()?.slice(0, 6),
    //   name: 'actualStart',
    //   label: 'Actual Start',
    //   type: 'datetime-local',
    //   component: 'input',
    // },
    // {
    //   id: uuid()?.slice(0, 6),
    //   name: 'actualEnd',
    //   label: 'Actual End',
    //   type: 'datetime-local',
    //   component: 'input',
    // },
    {
      id: uuid()?.slice(0, 6),
      name: 'duration',
      label: 'Duration',
      type: 'number',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'durationType',
      label: 'Duration Type',
      type: 'text',
      component: 'select',
      required: '1',
      options: duration,
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'workType_id',
      label: 'Work Type',
      type: 'text',
      component: 'select',
      required: '1',
      options: workTypes,
    },
    {
      id: uuid()?.slice(0, 9),
      name: 'stage',
      label: 'Stage',
      type: 'text',
      component: 'select',
      required: '1',
      hidden: true,
      options: [
        { id: 10, name: 'New' },
        { id: 20, name: 'Scheduled' },
        { id: 30, name: 'Dispatch' },
        { id: 40, name: 'On Route' },
        { id: 50, name: 'On Site' },
        { id: 100, name: 'Completed' },
        { id: 110, name: 'Can Not Complete' },
        { id: 120, name: 'Cancel' },
      ],
    },
  ];
};

export const inspectionFieldsModel = () => {
  return [
    {
      id: 1,
      name: 'emergencyPhoneWorking',
      label: 'Emergency Phone Working',
      type: 'radio',
      options: radioOptionsYesNo,
    },
    {
      id: 2,
      name: 'blackDiscVisibleWaterClear',
      label: 'Black Disc Visible/Water Clear',
      type: 'radio',
      options: radioOptionsYesNo,
    },
    {
      id: 3,
      name: 'mainDrainSecure',
      label: 'Main Drain Secure',
      type: 'radio',
      options: radioOptionsYesNo,
    },
    {
      id: 4,
      name: 'rescueEquipmentOnDeck',
      label: 'Rescue Equipment on Deck',
      type: 'radio',
      options: radioOptionsYesNo,
    },
    {
      id: 7,
      name: 'skimmerLidsBasketsConditions',
      label: 'Skimmer Lids/Baskets Conditions',
      type: 'radio',
      options: [
        {
          id: 1,
          label: 'Intact',
          value: '1',
        },
        {
          id: 2,
          label: 'Broken',
          value: '0',
        },
      ],
      afterSelect: {
        value: '0',
        field: {
          name: 'skimmerLidsBasketsConditionsBrokenQuantity',
          label: 'Broken Quantity',
          type: 'text',
          component: 'input',
        },
      },
    },
    {
      id: 6,
      name: 'chlorineBinFull:',
      label: 'Chlorine Bin Full:',
      type: 'radio',
      options: radioOptionsCapacity,
    },
    {
      id: 5,
      name: 'bromineReading',
      label: 'Bromine Reading',
      type: 'text',
      component: 'input',
      placeholder: '4-8ppm',
    },
    {
      id: 8,
      name: 'waterMeterReading',
      label: 'Water Meter Reading',
      type: 'text',
      component: 'input',
      placeholder: '',
    },
  ];
};
