import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import OperatingHourForm from './components/OperatingHourForm';
import OperatingHoursList from './components/OperatingHoursList';
import { addOperatingHour } from 'utils/functions/admin/operating_hours-func';
import { getAllOperatingHours } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';

const OperatingHours = ({ getAllOperatingHours, operatingHours }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Operating Hour'
        confirmBtnText='Add'
        onConfirm={values => addOperatingHour(values)}
      >
        <OperatingHourForm />
      </ModalForm>

      <AdminHeader
        name='Operating Hours'
        img='operating_hours'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllOperatingHours()}
      />

      <OperatingHoursList operatingHours={operatingHours} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    operatingHours: state?.constants?.operatingHours,
  };
};

export default connect(mapStateToProps, { getAllOperatingHours })(OperatingHours);
