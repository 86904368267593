import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router';
import LoginForm from './components/LoginForm';

const Login = ({ user }) => {
	// const navigate = useNavigate();
	useEffect(() => {
		// if (user) {
		// 	navigate('/dashboard');
		// }
		// eslint-disable-next-line
	}, []);
	return (
		<div className="login_page">
			<div className="row m-0 p-3 p-lg-5 h-100">
				<div className="col-12 col-lg-6 min-h-100 p-lg-4 rounded-3 d-flex flex-column justify-content-start justify-content-md-center">
					<div className="text-center">
						<img
							alt=""
							className="logo mt-5 mt-md-0 mb-4 mb-md-5 p-3"
							src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}
						/>
					</div>
					<LoginForm />
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	const { user } = state;
	return { user: user?.user };
}

export default connect(mapStateToProps)(Login);
