import React from 'react';
import { BiShow } from 'react-icons/bi';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';

const TableActions = ({ onShow, onDelete, onEdit }) => {
	return (
		<>
			{onShow ? (
				<button
					type="button"
					title="See details"
					className="bg-transparent border-0 px-2"
					onClick={onShow}
				>
					<BiShow size={20} fill="gray" />
				</button>
			) : (
				''
			)}
			{onEdit ? (
				<button
					type="button"
					title="Edit"
					className="bg-transparent border-0 px-2"
					onClick={onEdit}
				>
					<RiEdit2Line size={20} fill="gray" />
				</button>
			) : (
				''
			)}
			{onDelete ? (
				<button
					type="button"
					title="Delete"
					className="bg-transparent border-0 px-2"
					onClick={onDelete}
				>
					<RiDeleteBin6Line size={20} fill="gray" />
				</button>
			) : (
				''
			)}
		</>
	);
};

export default TableActions;
