import toast from 'react-hot-toast';

export const toastError = (
  text = 'Something went wrong , Try again later !',
  duration = 5000,
  position = 'top-center',
  hideProgress = true
) => {
  toast.error(text, {
    draggable: true,
    duration,
    position,
    hideProgress,
  });
};

export const toastSuccess = (
  text = 'Done !',
  duration = 5000,
  position = 'top-center',
  hideProgress = true
) => {
  toast.success(text, {
    draggable: true,
    duration,
    position,
    hideProgress,
  });
};
