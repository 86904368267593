import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import ModalT from 'components/shared/Modal/ModalT';
import ModalForm from 'components/shared/Modal/ModalForm';
import CategoryForm from './CategoryForm';
import TableActions from 'components/shared/table/TableActions';
import { deleteCategoryById, editCategory, setCategoryActivity } from 'utils/functions/admin/category-func';
import { useLocation } from 'react-router';

const CategoriesList = ({ data, type }) => {
  const location = useLocation();
  const [stagedItem, setStagedItem] = useState({});
  const [descColumn, setDescColumn] = useState('Description');

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(data?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(data?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  useEffect(() => {
    if (type === 'asset-property') {
      setDescColumn('Type');
    } else if (type === 'resource-priority') {
      setDescColumn('Priority');
    } else {
      setDescColumn('Description');
    }
  }, [type, location?.pathname]);

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteCategoryById(stagedItem?.id)}
      />

      <ModalForm
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editCategory(values, stagedItem?.id)}
      >
        <CategoryForm type={type} />
      </ModalForm>

      <div className='p-3'>
        <Table
          striped
          bordered
          responsive
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>{descColumn}</th>
              <th>Activity</th>
              <th>Created Date</th>
              <th>Last Modified Date</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map(item => (
              <tr key={item?.id}>
                <td>{item?.name}</td>
                <td>{item?.slug}</td>
                <td>
                  <Form.Check
                    type='switch'
                    className='activity_switch'
                    label={`${item?.status === 1 ? 'Active' : 'Deactive'}`}
                    checked={item?.status === 1 ? 1 : 0}
                    onChange={() => setCategoryActivity(item?.id, item?.status === 0 ? 1 : 0)}
                  />
                </td>
                <td>{item?.CreatedDate || '-'}</td>
                <td>{item?.LastModifiedDate || '-'}</td>
                <td className='text-center'>
                  <TableActions
                    onDelete={() => toggle(item?.id)}
                    onEdit={() => toggleEdit(item?.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default CategoriesList;
