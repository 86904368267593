import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import WorkTypeForm from './components/WorkTypeForm';
import WorkTypesList from './components/WorkTypesList';
import { addWorktype } from 'utils/functions/admin/worktype-func';
import { getAllWorkTypes } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';

const WorkTypes = ({ getAllWorkTypes, workTypes }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Work Type'
        confirmBtnText='Add'
        onConfirm={values => addWorktype(values)}
      >
        <WorkTypeForm />
      </ModalForm>

      <AdminHeader
        name='Work Types'
        img='work_type'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllWorkTypes()}
      />

      <WorkTypesList workTypes={workTypes} />
    </>
  );
};

const mapStateToProps = state => {
  return { workTypes: state?.constants?.workTypes };
};

export default connect(mapStateToProps, { getAllWorkTypes })(WorkTypes);
