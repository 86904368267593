import { CLEAR_LOADING, CLEAR_LOADING_ADMIN, SET_LOADING, SET_LOADING_ADMIN } from '../actionTypes';

const initialState = {
  isLoading: false,
};

const loaderReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_LOADING_ADMIN:
      return {
        ...state,
        isLoadingAdmin: true,
      };
    case CLEAR_LOADING_ADMIN:
      return {
        ...state,
        isLoadingAdmin: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
