import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useEffect, useState } from 'react';
import CategoryForm from './components/CategoryForm';
import CategoriesList from './components/CategoriesList';
import { connect } from 'react-redux';
import { addCategory } from 'utils/functions/admin/category-func';
import { getAllCategories } from 'redux/actions/constantsActions';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useLocation } from 'react-router';
import classNames from 'classnames';

const Categories = ({ type, img, name, data, getAllCategories, assetPropertyTypes }) => {
  const location = useLocation();
  const [dataShowing, setDataShowing] = useState(data?.[type]);
  const [filter, setFilter] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  useEffect(() => {
    setDataShowing(data?.[type]);
    // eslint-disable-next-line
  }, [location?.pathname, data]);

  useEffect(() => {
    getAllCategories(true);
    // eslint-disable-next-line
  }, [location?.pathname]);

  useEffect(() => {
    if (type === 'asset-property') {
      if (filter) {
        setDataShowing(data?.[type]?.filter(a => a.slug === filter));
      } else {
        setDataShowing(data?.[type]);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const onSelectType = filter => {
    setFilter(filter);
    if (filter) {
      setDataShowing(data?.[type]?.filter(a => a.slug === filter));
    } else {
      setDataShowing(data?.[type]);
    }
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title={`Add New ${name}`}
        confirmBtnText='Add'
        initialValues={type === 'asset-property' ? { slug: filter } : null}
        onConfirm={values => addCategory(values, type)}
      >
        <CategoryForm type={type} />
      </ModalForm>

      <AdminHeader
        name={name}
        img={img}
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllCategories(true)}
      />

      {type === 'asset-property' ? (
        <>
          <div className='py-3 text-center'>
            <ButtonGroup className='flex-wrap'>
              <Button
                variant='outline-primary'
                className={classNames('border px-5', { active: !filter })}
                onClick={() => onSelectType(false)}
              >
                All
              </Button>
              {assetPropertyTypes?.map(item => (
                <Button
                  key={item?.name}
                  variant='outline-primary'
                  className={classNames('border px-4', { active: filter === item?.name })}
                  onClick={() => onSelectType(item?.name)}
                >
                  {item?.name}s
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </>
      ) : (
        ''
      )}

      <CategoriesList
        data={dataShowing}
        type={type}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.categories,
    assetPropertyTypes: state?.constants?.categories?.['asset-property-type'],
  };
};

export default connect(mapStateToProps, { getAllCategories })(Categories);
