import {
  LOG_OUT,
  SET_ACCOUNT_FIELDS,
  SET_CONTACT_FIELDS,
  SET_SERVICE_CONTRACT_FIELDS,
  SET_UNITS,
  SET_CASE_FIELDS,
  SET_OPPORTUNITY_FIELDS,
  SET_QUOTES_FIELDS,
  SET_SKILLS,
  SET_WORK_TYPES,
  SET_CATEGORIES,
  SET_LEVELS,
  SET_SCHEDULING_CONSTRAINTS,
  SET_MAINTENANCE_PLANS_FIELDS,
  SET_ROLES,
  SET_USERS_LIST,
  SET_TERRITORIES,
  SET_OPERATING_HOURS,
  SET_OPTIONS,
  SET_TAX_GROUPS,
  SET_WORK_ORDER_FIELDS,
  SET_SERVICE_APPOINTMENTS_FIELDS,
  SET_HOLIDAY_GROUPS,
  SET_ALL_FIELDS,
  SET_FORM_GROUPS,
} from '../actionTypes';

const initialState = null;

export const categoryTypes = [
  'product',
  'salutation',
  'resource-types',
  'asset-property-type',
  'asset-types',
  'asset-property',
  'case-status',
  'case-reason',
  'case-priority',
  'case-type',
  'work_order-type',
  'case-origin',
  'opportunity-loss',
  'contract-type',
  'resource-priority',
  'account-industry',
  'contact-position',
  'opportunity-type',
];
export const optionTypes = ['token', 'wo-generation', 'template'];
export const unitTypes = ['measurement', 'currency', 'duration'];
export const addressTypes = ['billing', 'shipping', 'mailing', 'location'];
export const fieldsetTypes = ['inspection'];
export const formGroupTypes = ['asset-spa', 'asset-fountain', 'asset-pool', 'asset-fitness'];

const constantsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_UNITS:
      let unitState = { ...state?.units };
      for (let i = 0; i < unitTypes.length; i++) {
        unitState[unitTypes[i]] = action?.[unitTypes[i]];
      }
      return {
        ...state,
        units: unitState,
      };
    case SET_OPTIONS:
      let optionState = { ...state?.options };
      for (let i = 0; i < optionTypes.length; i++) {
        optionState[optionTypes[i]] = action?.[optionTypes[i]];
      }
      return {
        ...state,
        options: optionState,
      };
    case SET_ALL_FIELDS:
      let fieldsetState = { ...state?.fieldsets };
      for (let i = 0; i < fieldsetTypes.length; i++) {
        fieldsetState[fieldsetTypes[i]] = action?.[fieldsetTypes[i]];
      }
      return {
        ...state,
        fieldsets: fieldsetState,
      };
    case SET_FORM_GROUPS:
      let formGroupState = { ...state?.formGroups };
      for (let i = 0; i < formGroupTypes.length; i++) {
        formGroupState[formGroupTypes[i]] = action?.[formGroupTypes[i]];
      }
      return {
        ...state,
        formGroups: formGroupState,
      };
    case SET_CATEGORIES:
      let categoryState = { ...state?.categories };
      for (let i = 0; i < categoryTypes.length; i++) {
        categoryState[categoryTypes[i]] = action?.[categoryTypes[i]];
      }
      return {
        ...state,
        categories: categoryState,
      };
    case SET_SKILLS:
      return {
        ...state,
        skills: action?.skills,
      };
    case SET_TERRITORIES:
      return {
        ...state,
        territories: action?.territories,
      };
    case SET_OPERATING_HOURS:
      return {
        ...state,
        operatingHours: action?.operatingHours,
      };
    case SET_LEVELS:
      return {
        ...state,
        levels: action?.levels,
      };
    case SET_TAX_GROUPS:
      return {
        ...state,
        taxGroups: action?.taxGroups,
      };
    case SET_HOLIDAY_GROUPS:
      return {
        ...state,
        holidayGroups: action?.holidayGroups,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action?.roles,
      };
    case SET_USERS_LIST:
      return {
        ...state,
        usersList: action?.usersList,
      };
    case SET_SCHEDULING_CONSTRAINTS:
      return {
        ...state,
        schedulingConstraints: action?.schedulingConstraints,
      };
    case SET_WORK_TYPES:
      return {
        ...state,
        workTypes: action?.workTypes,
      };
    case SET_ACCOUNT_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          account: action?.fields,
        },
      };

    case SET_CONTACT_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          contact: action?.fields,
        },
      };
    case SET_SERVICE_CONTRACT_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          serviceContract: action?.fields,
        },
      };
    case SET_MAINTENANCE_PLANS_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          maintenancePlan: action?.fields,
        },
      };
    case SET_CASE_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          case: action?.fields,
        },
      };
    case SET_OPPORTUNITY_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          opportunity: action?.fields,
        },
      };
    case SET_QUOTES_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          quote: action?.fields,
        },
      };
    case SET_WORK_ORDER_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          workOrder: action?.fields,
        },
      };
    case SET_SERVICE_APPOINTMENTS_FIELDS:
      return {
        ...state,
        fields: {
          ...state?.fields,
          serviceAppointment: action?.fields,
        },
      };
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default constantsReducer;
