import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { axiosHeaders } from 'utils/axiosHeaders';
import { SET_TAX_GROUPS } from 'redux/actionTypes';

export const addTaxGroup = async values => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };
  if (showLogs) {
    console.log('addTaxGroup : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/tax/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/tax/add ', res);
  }
  if (res?.status) {
    toastSuccess(`The ${values?.name} has been added successfully!`);
    store.dispatch({
      type: SET_TAX_GROUPS,
      taxGroups: res?.data,
    });
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editTaxGroup = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const data = {
    data: values,
  };

  if (showLogs) {
    console.log('editTaxGroup', data);
  }

  const fetchData = await axios.post(`${apiUrl}/tax/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/tax/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_TAX_GROUPS,
      taxGroups: res?.data,
    });
    toastSuccess(`The ${values?.name} Tax Group has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteTaxGroupById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/tax/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/tax/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_TAX_GROUPS,
      taxGroups: res?.data,
    });
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
