import React, { useState } from 'react';
import ModalT from 'components/shared/Modal/ModalT';
import ModalForm from 'components/shared/Modal/ModalForm';
import SchedulingConstraintForm from './SchedulingConstraintForm';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import {
  deleteSchedulingConstraintsById,
  editSchedulingConstraints,
} from 'utils/functions/admin/constraints-func';

const SchedulingConstraintList = ({ constraints }) => {
  const [stagedItem, setStagedItem] = useState({});

  const [modal, setModal] = useState(false);
  const toggle = id => {
    if (id) {
      setStagedItem(constraints?.find(u => u.id === id));
    }
    setModal(prevState => !prevState);
  };
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = id => {
    if (id) {
      setStagedItem(constraints?.find(u => u.id === id));
    }
    setModalEdit(prevState => !prevState);
  };

  return (
    <>
      <ModalT
        modal={modal}
        toggle={toggle}
        title={`Delete ${stagedItem?.name}`}
        message={`Are you sure you want to delete ${stagedItem?.name}?`}
        confirmBtnText='Yes'
        confirmColor='warning'
        onConfirm={() => deleteSchedulingConstraintsById(stagedItem?.id)}
      />
      <ModalForm
        modal={modalEdit}
        toggle={toggleEdit}
        title={`Edit ${stagedItem?.name}`}
        initialValues={stagedItem}
        confirmBtnText='Save'
        onConfirm={values => editSchedulingConstraints(values, stagedItem?.id)}
      >
        <SchedulingConstraintForm />
      </ModalForm>
      <div className='row m-0 p-3'>
        {constraints?.map(item => (
          <div
            className='col-12 col-md-6 col-xl-4 col-xxl-3 p-3'
            key={item?.id}
          >
            <div
              className='border shadow p-3'
              style={{ backgroundColor: 'azure' }}
            >
              <div className='text-center'>
                <h5 className='d-inline-block ms-3'>{item?.name}</h5>
              </div>
              <hr className='mt-1' />
              <div>
                <span>Maximum Shift Per Day :</span>
                <h6 className='d-inline-block ms-3'>{item?.shiftPerDay || 0}</h6>
              </div>
              <div>
                <span>Maximum Shift Per Week :</span>
                <h6 className='d-inline-block ms-3'>{item?.shiftPerWeek || 0}</h6>
              </div>
              <div>
                <span>Maximum Shift Per Month :</span>
                <h6 className='d-inline-block ms-3'>{item?.shiftPerMonth || 0}</h6>
              </div>
              <div>
                <span>Maximum Non Standard Shift Per Month :</span>
                <h6 className='d-inline-block ms-3'>{item?.nonStandardShiftPerMonth || 0}</h6>
              </div>
              <div>
                <span>Maximum Working Hours Per Day :</span>
                <h6 className='d-inline-block ms-3'>{item?.workHourPerDay || 0}</h6>
              </div>
              <div>
                <span>Maximum Working Hours Per Week :</span>
                <h6 className='d-inline-block ms-3'>{item?.workHourPerWeek || 0}</h6>
              </div>
              <div>
                <span>Maximum Working Hours Per Month :</span>
                <h6 className='d-inline-block ms-3'>{item?.workHourPerMonth || 0}</h6>
              </div>
              <hr />
              <div className='d-flex justify-content-center align-items-center mt-2'>
                <button
                  type='button'
                  title='Edit'
                  className='bg-transparent border-0 px-2'
                  onClick={() => toggleEdit(item?.id)}
                >
                  <RiEdit2Line
                    size={20}
                    fill='gray'
                  />
                </button>
                <button
                  type='button'
                  title='Delete'
                  className='bg-transparent border-0 px-2'
                  onClick={() => toggle(item?.id)}
                >
                  <RiDeleteBin6Line
                    size={20}
                    fill='gray'
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SchedulingConstraintList;
