import React, { useRef, useEffect, useState } from 'react';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { googleMapsApiKey } from 'app/Rules';

// export const addressFields = () => {
//   let fields = {};
//   for (const type of addressTypes) {
//     fields[`${type}`] = [
//       {
//         id: uuid()?.slice(0, 6),
//         name: `${type}_country`,
//         label: 'Country',
//         type: 'text',
//         component: 'input',
//       },
//       {
//         id: uuid()?.slice(0, 6),
//         name: `${type}_state`,
//         label: 'State',
//         type: 'text',
//         component: 'input',
//       },
//       {
//         id: uuid()?.slice(0, 6),
//         name: `${type}_city`,
//         label: 'City',
//         type: 'text',
//         component: 'input',
//       },
//       {
//         id: uuid()?.slice(0, 6),
//         name: `${type}_street`,
//         label: 'Street',
//         type: 'text',
//         component: 'input',
//       },
//       {
//         id: uuid()?.slice(0, 6),
//         name: `${type}_postal_code`,
//         label: 'Postal Code',
//         type: 'text',
//         component: 'input',
//       },
//     ];
//   }
//   return fields;
// };

export const addressFields = {
  billing: [
    {
      id: uuid()?.slice(0, 6),
      name: 'billing_country',
      label: 'Country',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'billing_state',
      label: 'State',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'billing_city',
      label: 'City',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'billing_street',
      label: 'Street',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'billing_postal_code',
      label: 'Postal Code',
      type: 'text',
      component: 'input',
    },
  ],
  shipping: [
    {
      id: uuid()?.slice(0, 6),
      name: 'shipping_country',
      label: 'Country',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'shipping_state',
      label: 'State',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'shipping_city',
      label: 'City',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'shipping_street',
      label: 'Street',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'shipping_postal_code',
      label: 'Postal Code',
      type: 'text',
      component: 'input',
    },
  ],
  mailing: [
    {
      id: uuid()?.slice(0, 6),
      name: 'mailing_country',
      label: 'Country',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'mailing_state',
      label: 'State',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'mailing_city',
      label: 'City',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'mailing_street',
      label: 'Street',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'mailing_postal_code',
      label: 'Postal Code',
      type: 'text',
      component: 'input',
    },
  ],
  location: [
    {
      id: uuid()?.slice(0, 6),
      name: 'location_country',
      label: 'Country',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'location_state',
      label: 'State',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'location_city',
      label: 'City',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'location_street',
      label: 'Street',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'location_postal_code',
      label: 'Postal Code',
      type: 'text',
      component: 'input',
    },
  ],
};

export const initialAddressValues = (values, type) => {
  const makeData = type => {
    let data = { ...values };
    data[`${type}address`] = values?.[`${type}address`];
    data[`${type}_country`] = values?.[`${type}_country`];
    data[`${type}_state`] = values?.[`${type}_state`];
    data[`${type}_city`] = values?.[`${type}_city`];
    data[`${type}_street`] = values?.[`${type}_street`];
    data[`${type}_postal_code`] = values?.[`${type}_postal_code`];
    data[`${type}_latitude`] = values?.[`${type}_latitude`];
    data[`${type}_longitude`] = values?.[`${type}_longitude`];
    return data;
  };

  switch (type) {
    case 'billing':
      return makeData('billing');

    case 'shipping':
      return makeData('shipping');

    case 'mailing':
      return makeData('mailing');

    case 'location':
      return makeData('location');

    default:
      break;
  }
};

const ModalAddress = ({ modal, toggle, initialValues, submitAddress, type }) => {
  const inputRef = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState({});
  useEffect(() => {
    if (modal) {
      setSelectedAddress({ ...initialValues });
    }
    // eslint-disable-next-line
  }, [modal]);

  const onPlacesChanged = () => {
    const fetchData = inputRef?.current?.getPlaces()[0];
    const components = fetchData?.address_components;

    const makeData = type => {
      let data = {};
      data[`${type}address`] = fetchData?.formatted_address;
      data[`${type}_country`] = components?.find(a => a?.types[0] === 'country')?.long_name;
      data[`${type}_state`] = components?.find(a => a?.types[0] === 'administrative_area_level_1')?.long_name;
      data[`${type}_city`] = components?.find(a => a?.types[0] === 'locality')?.long_name;
      data[`${type}_street`] = components?.find(a => a?.types[0] === 'route')?.long_name;
      data[`${type}_postal_code`] = components?.find(a => a?.types?.includes('postal_code'))?.long_name;
      data[`${type}_latitude`] = fetchData?.geometry?.location?.lat();
      data[`${type}_longitude`] = fetchData?.geometry?.location?.lng();
      return data;
    };

    switch (type) {
      case 'billing':
        setSelectedAddress(makeData('billing'));
        break;

      case 'shipping':
        setSelectedAddress(makeData('shipping'));
        break;

      case 'mailing':
        setSelectedAddress(makeData('mailing'));
        break;

      case 'location':
        setSelectedAddress(makeData('location'));
        break;

      default:
        break;
    }
  };

  const onSubmit = values => {
    submitAddress({ ...initialValues, ...values });
    toggle();
  };

  const fields = () => {
    switch (type) {
      case 'billing':
        return addressFields?.billing;

      case 'shipping':
        return addressFields?.shipping;

      case 'mailing':
        return addressFields?.mailing;

      case 'location':
        return addressFields?.location;

      default:
        break;
    }
  };

  return (
    <Modal
      show={modal}
      onHide={toggle}
    >
      <Modal.Header closeButton>
        <Modal.Title className='text-center w-100 border-bottom pb-3 text-capitalize'>
          {type} Address
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        initialValues={selectedAddress}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {window.google === undefined && modal ? (
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={['places']}
                >
                  <StandaloneSearchBox
                    onLoad={ref => (modal ? (inputRef.current = ref) : null)}
                    onPlacesChanged={() => onPlacesChanged()}
                  >
                    <>
                      <label className='form-label'>Address</label>
                      <input
                        type='text'
                        placeholder='Search here ...'
                        className='form-control'
                      />
                    </>
                  </StandaloneSearchBox>
                </LoadScript>
              ) : (
                ''
              )}

              {window.google !== undefined && modal ? (
                <StandaloneSearchBox
                  onLoad={ref => (modal ? (inputRef.current = ref) : null)}
                  onPlacesChanged={() => onPlacesChanged()}
                >
                  <>
                    <label className='form-label'>Address</label>
                    <input
                      type='text'
                      placeholder='Search here ...'
                      className='form-control'
                    />
                  </>
                </StandaloneSearchBox>
              ) : (
                ''
              )}

              <div className='row m-0 mt-3'>
                {fields()?.map(item => (
                  <div
                    className='col-12 col-md-6 my-3'
                    key={item?.id}
                  >
                    <label className='form-label'>{item?.label}</label>
                    <Field
                      name={item?.name}
                      component='input'
                      className='form-control'
                      type={item?.type}
                      required={item?.required === '1'}
                    />
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer className='text-center w-100'>
              <Button
                className='mx-auto'
                variant='secondary'
                onClick={() => toggle(false)}
                style={{ width: '90px' }}
              >
                Cancel
              </Button>
              <Button
                className='mx-auto'
                variant='success'
                type='submit'
                style={{ width: '130px' }}
              >
                Save
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};

export default ModalAddress;
