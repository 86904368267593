import { store } from 'app/store';
import {
  setMainItemActive,
  setPrimaryActiveTab,
  setPrimaryLastTab,
  setPrimaryTabs,
} from 'redux/actions/tabsActions';

const insertAtOldPosition = (array, index, ...elementsArray) => {
  array.splice(index, 0, ...elementsArray);
};

export const onSelectMainItem = item => {
  const homeTab = store?.getState()?.tabs?.primary[0];
  store.dispatch(setPrimaryActiveTab(homeTab));
  store.dispatch(setMainItemActive(item));
};

// Primary Tabs
export const onSelectTab = item => {
  if (store?.getState()?.tabs?.primary[0]?.id !== 1) {
    const homeTab = store?.getState()?.tabs?.primary?.find(t => t.id === 1);
    const otherTabs = store?.getState()?.tabs?.primary?.filter(t => t.id !== 1);
    store.dispatch(setPrimaryTabs([homeTab, ...otherTabs]));
    store.dispatch(setPrimaryActiveTab(item));
  } else {
    if (store?.getState()?.tabs?.primary[0]?.id === 1) {
      const homeTab = store?.getState()?.tabs?.primary?.find(t => t.id === 1);
      store.dispatch(setPrimaryActiveTab(homeTab));
    }

    const lastTab = store?.getState()?.tabs?.primaryActive;
    if (item?.id !== lastTab?.id) {
      store.dispatch(setPrimaryLastTab(lastTab));
    }
    store.dispatch(setPrimaryActiveTab(item));
  }
};

export const onCloseTab = async id => {
  const prevTabs = store?.getState()?.tabs?.primary;
  const activeTab = store?.getState()?.tabs?.primaryActive;
  const lastActive = store?.getState()?.tabs?.primaryLast;

  store.dispatch(setPrimaryTabs(prevTabs?.filter(t => t.id !== id)));

  if (activeTab?.id === id) {
    store.dispatch(setPrimaryActiveTab(lastActive));
    store.dispatch(setPrimaryLastTab(prevTabs[0]));
  } else {
    if (lastActive?.id === id) {
      store.dispatch(setPrimaryActiveTab(activeTab));
      store.dispatch(setPrimaryLastTab(prevTabs[0]));
    }
  }
};

export const onAddTab = (title, type, data, icon = false, hasClose = true, hasDrop = true) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  const prevTabs = store?.getState()?.tabs?.primary;
  const madeBy = store?.getState()?.tabs?.primaryActive;

  const newId = Math.floor(Math.random() * 100000);

  const newSubTab = {
    id: newId,
    title,
    type,
    data,
    icon,
    hasClose: false,
    hasDrop,
  };

  prevTabs?.push({
    id: newId,
    title,
    type,
    data,
    icon,
    hasClose,
    hasDrop,
    subTabs: [newSubTab],
    activeSub: newSubTab,
    lastSub: newSubTab,
  });
  store.dispatch(setPrimaryTabs(prevTabs));
  store.dispatch(setPrimaryActiveTab(prevTabs[prevTabs.length - 1]));
  store.dispatch(setPrimaryLastTab(madeBy));
};

// Sub Tabs
export const onSelectSubTab = item => {
  const activeSubTab = store?.getState()?.tabs?.primaryActive?.activeSub;
  const lastSubTab = store?.getState()?.tabs?.primaryActive?.lastSub;
  const prevTabs = store?.getState()?.tabs?.primary;
  const parentTab = store?.getState()?.tabs?.primaryActive;
  const parentIndex = prevTabs?.indexOf(parentTab);

  const tempPrimaryTabs = prevTabs?.filter(t => t.id !== parentTab?.id);

  let newPrimaryTab = {
    ...parentTab,
    activeSub: item,
    lastSub: activeSubTab,
  };

  if (item?.id === activeSubTab?.id) {
    newPrimaryTab = {
      ...parentTab,
      activeSub: item,
      lastSub: lastSubTab,
    };
  }

  insertAtOldPosition(tempPrimaryTabs, parentIndex, newPrimaryTab);

  store.dispatch(setPrimaryTabs(tempPrimaryTabs));
  store.dispatch(setPrimaryActiveTab(newPrimaryTab));
};

export const onAddSubTab = (title, type, data, icon = false, hasClose = true, hasDrop = true) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  const prevTabs = store?.getState()?.tabs?.primary;
  const parentTab = store?.getState()?.tabs?.primaryActive;
  const parentIndex = prevTabs?.indexOf(parentTab);
  const prevSubTabs = store?.getState()?.tabs?.primaryActive?.subTabs;
  const madeBy = store?.getState()?.tabs?.primaryActive?.activeSub;

  const tempPrimaryTabs = prevTabs?.filter(t => t.id !== parentTab?.id);

  const newId = Math.floor(Math.random() * 100000);

  const newSubTab = {
    id: newId,
    title,
    type,
    data,
    icon,
    hasClose,
    hasDrop,
  };

  const newPrimaryTab = {
    ...parentTab,
    subTabs: [...prevSubTabs, newSubTab],
    activeSub: newSubTab,
    lastSub: madeBy,
  };

  insertAtOldPosition(tempPrimaryTabs, parentIndex, newPrimaryTab);

  store.dispatch(setPrimaryTabs(tempPrimaryTabs));
  store.dispatch(setPrimaryActiveTab(newPrimaryTab));
};

export const onCloseSubTab = async id => {
  const prevTabs = store?.getState()?.tabs?.primary;
  const parentTab = store?.getState()?.tabs?.primaryActive;
  const parentIndex = prevTabs?.indexOf(parentTab);
  const prevSubTabs = store?.getState()?.tabs?.primaryActive?.subTabs;
  const activeSubTab = store?.getState()?.tabs?.primaryActive?.activeSub;
  const lastSubActive = store?.getState()?.tabs?.primaryActive?.lastSub;

  const tempPrimaryTabs = prevTabs?.filter(t => t.id !== parentTab?.id);

  let newPrimaryTab = {
    ...parentTab,
    subTabs: prevSubTabs?.filter(t => t.id !== id),
    activeSub: lastSubActive,
    lastSub: prevSubTabs[0],
  };

  if (activeSubTab?.id === id) {
    newPrimaryTab = {
      ...newPrimaryTab,
      activeSub: lastSubActive,
    };
  } else {
    newPrimaryTab = {
      ...newPrimaryTab,
      activeSub: activeSubTab,
    };
  }

  insertAtOldPosition(tempPrimaryTabs, parentIndex, newPrimaryTab);

  store.dispatch(setPrimaryTabs(tempPrimaryTabs));
  store.dispatch(setPrimaryActiveTab(newPrimaryTab));
};
