import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllOptions } from 'redux/actions/constantsActions';
import { useLocation } from 'react-router';
import TemplateCard from './components/TemplateCard';

const Templates = ({ data, getAllOptions }) => {
  const location = useLocation();

  const [dataShowing, setDataShowing] = useState(data);

  useEffect(() => {
    setDataShowing(data);
    // eslint-disable-next-line
  }, [location?.pathname, data]);

  return (
    <>
      <AdminHeader
        name='Templates'
        img='template'
        onUpdate={() => getAllOptions(true)}
        showSearch={false}
      />
      <div className='d-flex row m-0 p-0 col-12 my-1'>
        {dataShowing?.map((item, i) => (
          <div
            key={item?.id}
            className='col-12 col-md-6 col-xl-3 col-xxl-3 mb-2 py-1'
          >
            <TemplateCard template={item} />
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.options?.['template'],
  };
};

export default connect(mapStateToProps, { getAllOptions })(Templates);
