import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useState } from 'react';
import LevelForm from './components/LevelForm';
import LevelsList from './components/LevelsList';
import { addLevel } from 'utils/functions/admin/level-func';
import { getAllLevels } from 'redux/actions/constantsActions';
import { connect } from 'react-redux';

const Levels = ({ getAllLevels, levels }) => {
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title='Add New Level'
        confirmBtnText='Add'
        onConfirm={values => addLevel(values)}
      >
        <LevelForm />
      </ModalForm>

      <AdminHeader
        name='Levels'
        img='education'
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllLevels()}
      />

      <LevelsList levels={levels} />
    </>
  );
};

const mapStateToProps = state => {
  return { levels: state?.constants?.levels };
};

export default connect(mapStateToProps, { getAllLevels })(Levels);
