export const SidebarData = [
  {
    id: 1,
    title: 'Home',
    path: '/admin',
    img: 'home',
  },
  {
    id: 2,
    title: 'Users',
    img: 'user',
    subNav: [
      {
        title: 'Users List',
        path: '/admin/users/list',
        img: 'lead_list',
      },
      {
        title: 'Role and Permissions',
        path: '/admin/users/role',
        img: 'user_role',
      },
      {
        title: 'Account Industry',
        path: '/admin/users/account-industry',
        img: 'industry',
      },
      {
        title: 'Salutation',
        path: '/admin/users/salutation',
        img: 'salutation',
      },
      {
        title: 'Contact Position',
        path: '/admin/users/contact-position',
        img: 'contact_position',
      },
    ],
  },
  {
    id: 3,
    title: 'Service Resources',
    img: 'service_resource',
    subNav: [
      {
        title: 'Work Types',
        path: '/admin/service-resources/work-types',
        img: 'work_type',
      },
      {
        title: 'Skills',
        path: '/admin/service-resources/skills',
        img: 'skill',
      },
      {
        title: 'Levels',
        path: '/admin/service-resources/levels',
        img: 'education',
      },
      {
        title: 'Resource Types',
        path: '/admin/service-resources/resource-types',
        img: 'service_crew',
      },
      {
        title: 'Territories',
        path: '/admin/service-resources/territories',
        img: 'service_territory',
      },
      {
        title: 'Resource Priority',
        path: '/admin/service-resources/resource-priority',
        img: 'resource_priority',
      },
      {
        title: 'Scheduling constraint',
        path: '/admin/service-resources/scheduling-constraint',
        img: 'scheduling_constraints',
      },
      {
        title: 'Operating Hours',
        path: '/admin/service-resources/operating-hours',
        img: 'operating_hours',
      },
    ],
  },
  {
    id: 4,
    title: 'Classifications',
    img: 'category',
    subNav: [
      {
        title: 'Holiday groups',
        path: '/admin/classifications/holiday-group',
        img: 'holiday',
      },
      {
        title: 'Tax groups',
        path: '/admin/classifications/tax-group',
        img: 'tax_group',
      },
      {
        title: 'Currencies',
        path: '/admin/classifications/currency',
        img: 'currency',
      },
      {
        title: 'Product Categories',
        path: '/admin/classifications/product-categories',
        img: 'product',
      },
      {
        title: 'Asset Property Type',
        path: '/admin/classifications/asset-property-type',
        img: 'asset_property_type',
      },
      {
        title: 'Asset Types',
        path: '/admin/classifications/asset-types',
        img: 'asset_object',
      },
      {
        title: 'Asset Properties',
        path: '/admin/classifications/asset-properties',
        img: 'asset_action',
      },
      {
        title: 'Case Status',
        path: '/admin/classifications/case-status',
        img: 'case_status',
      },
      {
        title: 'Case Priority',
        path: '/admin/classifications/case-priority',
        img: 'case_priority',
      },
      {
        title: 'Case Type',
        path: '/admin/classifications/case-type',
        img: 'case_type',
      },
      {
        title: 'Work Order Type',
        path: '/admin/classifications/work_order-type',
        img: 'work_order_type',
      },
      {
        title: 'Opportunity Loss Reasons',
        path: '/admin/classifications/opportunity-loss',
        img: 'opportunity_loss',
      },
      {
        title: 'Opportunity Type',
        path: '/admin/classifications/opportunity-type',
        img: 'opportunity',
      },
      {
        title: 'Contract Type',
        path: '/admin/classifications/contract-type',
        img: 'contract_type',
      },
      {
        title: 'Measurements',
        path: '/admin/classifications/measurement',
        img: 'dashboard',
      },
      {
        title: 'Durations',
        path: '/admin/classifications/duration',
        img: 'business_hours',
      },
    ],
  },
  {
    id: 5,
    title: 'Field Sets',
    img: 'fieldset',
    subNav: [
      {
        title: 'Inspection',
        path: '/admin/fieldset/inspection',
        img: 'inspection',
      },
    ],
  },
  {
    id: 6,
    title: 'Settings',
    img: 'settings',
    subNav: [
      {
        title: 'Tokens',
        path: '/admin/settings/tokens',
        img: 'tokens',
      },
      {
        title: 'WO Generation Time',
        path: '/admin/settings/wo-generation',
        img: 'date_time',
      },
      {
        title: 'Templates',
        path: '/admin/settings/templates',
        img: 'template',
      },
    ],
  },
];
