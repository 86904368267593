import React, { useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import InputValidation, { validatorConfigs } from '../InputValidation';
import SimpleReactValidator from 'simple-react-validator';

const ModalTaxGroup = ({ modal, toggle, onConfirm, initialValues, confirmBtnText, title }) => {
  const validator = useRef(new SimpleReactValidator(validatorConfigs));

  const fields = [
    {
      id: uuid()?.slice(0, 6),
      name: 'name',
      label: 'Name',
      type: 'text',
      component: 'input',
      required: '1',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'hst',
      label: 'HST (%)',
      type: 'text',
      component: 'input',
      required: '1',
      validation: 'numeric',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'gst',
      label: 'GST (%)',
      type: 'text',
      component: 'input',
      required: '1',
      validation: 'numeric',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'pst',
      label: 'PST (%)',
      type: 'text',
      component: 'input',
      required: '1',
      validation: 'numeric',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'description',
      label: 'Description',
      type: 'text',
      component: 'input',
    },
    {
      id: uuid()?.slice(0, 6),
      name: 'status',
      label: 'Is Active',
      type: 'checkbox',
      component: 'input',
      defaultValue: 1,
    },
  ];

  const getTotal = taxValues => {
    return (
      Number(!isNaN(taxValues?.hst) ? taxValues?.hst : 0) +
      Number(!isNaN(taxValues?.gst) ? taxValues?.gst : 0) +
      Number(!isNaN(taxValues?.pst) ? taxValues?.pst : 0)
    ).toFixed(2);
  };

  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-center w-100 border-bottom pb-3'>{title}</Modal.Title>
          </Modal.Header>

          <Form
            initialValues={initialValues}
            onSubmit={values => {
              if (!validator.current.allValid()) {
                validator?.current?.showMessages();
              } else {
                toggle();
                onConfirm({
                  ...values,
                  total: getTotal(values),
                });
              }
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className='row m-0'>
                    {fields
                      ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
                      .map(item => (
                        <div
                          className='col-12 col-md-6'
                          key={item?.id}
                        >
                          <div className='mt-3'>
                            <label className='form-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-control'
                              type={item?.type}
                              required={item?.required === '1'}
                            />
                            <InputValidation
                              item={item}
                              values={values}
                              validator={validator}
                            />
                          </div>
                        </div>
                      ))}

                    <div className='col-12 col-md-6 col-lg-6 my-3 text-center'>
                      <label className='form-label'>Total Tax :</label>
                      <span
                        name='name'
                        component='input'
                        className='form-control border-0 border-start border-end border-top fw-bold'
                        type='text'
                        disabled
                        style={{ height: '29px', backgroundColor: 'aliceblue' }}
                      >
                        {getTotal(values)} %
                      </span>
                    </div>

                    <div className='form-check col-12 col-md-6 py-1 mt-3 px-5 d-flex justify-content-between'>
                      {fields
                        ?.filter(i => i.type === 'checkbox')
                        .map(item => (
                          <div key={item?.id}>
                            <label className='form-check-label'>{item?.label}</label>
                            <Field
                              name={item?.name}
                              component='input'
                              className='form-check-input'
                              type={item?.type}
                              required={item?.required === '1'}
                              defaultValue={item?.defaultValue}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='text-center w-100 mt-3'>
                  <Button
                    className='mx-auto'
                    variant='secondary'
                    onClick={() => toggle(false)}
                    style={{ width: '80px' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='mx-auto'
                    variant='success'
                    type='submit'
                    style={{ width: '110px' }}
                  >
                    {confirmBtnText}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ModalTaxGroup);
