import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllOptions } from 'redux/actions/constantsActions';
import { useLocation } from 'react-router';
import { editOption } from 'utils/functions/admin/options-func';

const WoGeneration = ({ data, getAllOptions }) => {
  const location = useLocation();
  const [edited, setEdited] = useState(false);
  const [dataShowing, setDataShowing] = useState([]);

  useEffect(() => {
    setDataShowing(JSON.parse(data?.[0]?.value || '[]')?.days);
    setEdited(false);
    // eslint-disable-next-line
  }, [location?.pathname, data]);

  const onChangeTime = async (time, id) => {
    const day = dataShowing?.find(d => d.id === id);
    const index = dataShowing?.indexOf(day);
    const oldData = dataShowing?.filter(d => d.id !== id);
    oldData?.splice(index, 0, { ...day, time });
    setEdited(true);
    setDataShowing(oldData);
  };

  return (
    <>
      <AdminHeader
        name='Work Order Generation Times'
        img='date_time'
        onUpdate={() => getAllOptions(true)}
        showSearch={false}
      />

      <div className='row m-0'>
        {dataShowing?.map(item => (
          <div
            className='col-12 col-md-4 col-lg-3 mx-auto text-center p-3'
            key={item?.id}
          >
            <div className='border rounded p-3'>
              <h4>{item?.label}</h4>
              <input
                value={item?.time}
                type='time'
                onChange={e => onChangeTime(e?.target?.value, item?.id)}
                className='mt-3 form-control'
              />
            </div>
          </div>
        ))}
      </div>

      <div className='text-center mt-5'>
        <button
          className='btn btn-success mx-auto mt-5'
          style={{ width: '25%' }}
          disabled={!edited}
          onClick={() =>
            editOption({ ...data?.[0], value: JSON.stringify({ days: dataShowing }) }, data?.[0]?.id)
          }
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.options?.['wo-generation'],
  };
};

export default connect(mapStateToProps, { getAllOptions })(WoGeneration);
