import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { store } from 'app/store';
import { toastError, toastSuccess } from 'components/shared/toast';

import { setLoadingAdmin, clearLoadingAdmin } from 'redux/actions/loaderActions';
import { axiosHeaders } from 'utils/axiosHeaders';
import { SET_ROLES } from 'redux/actionTypes';
import { permissionRules } from 'components/pages/admin/users/roles/components/ModalRole';

export const addRole = async values => {
  store.dispatch(setLoadingAdmin());
  const rule = {};
  for (let i = 0; i < permissionRules.length; i++) {
    rule[permissionRules[i]?.name] = values[permissionRules[i]?.name];
  }
  for (let i = 0; i < permissionRules.length; i++) {
    delete values[permissionRules[i]?.name];
  }
  const data = {
    data: { ...values, rule: JSON.stringify(rule) },
  };
  if (showLogs) {
    console.log('addRole : ', data);
  }

  const fetchData = await axios.post(`${apiUrl}/roles/add`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log('/roles/add ', res);
  }
  if (res?.status) {
    toastSuccess(`The ${values?.name} has been added successfully!`);
    store.dispatch({
      type: SET_ROLES,
      roles: res?.data,
    });
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const editRole = async (values, id) => {
  store.dispatch(setLoadingAdmin());
  const rule = {};
  for (let i = 0; i < permissionRules.length; i++) {
    rule[permissionRules[i]?.name] = values[permissionRules[i]?.name];
  }
  for (let i = 0; i < permissionRules.length; i++) {
    delete values[permissionRules[i]?.name];
  }
  const data = {
    data: { ...values, rule: JSON.stringify(rule) },
  };

  if (showLogs) {
    console.log('editRole', data);
  }

  const fetchData = await axios.post(`${apiUrl}/roles/update/${id}`, data, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/roles/update/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_ROLES,
      roles: res?.data,
    });
    toastSuccess(`The ${values?.name} Role has been edited successfully!`);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};

export const deleteRoleById = async id => {
  store.dispatch(setLoadingAdmin());

  const fetchData = await axios.post(`${apiUrl}/roles/delete/${id}`, {
    headers: axiosHeaders,
  });
  const res = fetchData?.data;
  store.dispatch(clearLoadingAdmin());
  if (showLogs) {
    console.log(`/roles/delete/${id}`, res);
  }
  if (res?.status) {
    store.dispatch({
      type: SET_ROLES,
      roles: res?.data,
    });
    toastSuccess(res?.message, 2500);
    return res?.status;
  } else {
    toastError();
    return {};
  }
};
