import axios from 'axios';
import { apiUrl, showLogs } from 'app/Rules';
import { axiosHeaders } from 'utils/axiosHeaders';
import {
  CLEAR_LOADING,
  CLEAR_LOADING_ADMIN,
  SET_LOADING_ADMIN,
  SET_ACCOUNT_FIELDS,
  SET_CONTACT_FIELDS,
  SET_SERVICE_CONTRACT_FIELDS,
  SET_UNITS,
  SET_LOADING,
  SET_CASE_FIELDS,
  SET_OPPORTUNITY_FIELDS,
  SET_QUOTES_FIELDS,
  SET_SKILLS,
  SET_WORK_TYPES,
  SET_CATEGORIES,
  SET_LEVELS,
  SET_SCHEDULING_CONSTRAINTS,
  SET_MAINTENANCE_PLANS_FIELDS,
  SET_ROLES,
  SET_USERS_LIST,
  SET_TERRITORIES,
  SET_OPERATING_HOURS,
  SET_OPTIONS,
  SET_TAX_GROUPS,
  SET_PRICEBOOKS,
  SET_WORK_ORDER_FIELDS,
  SET_SERVICE_APPOINTMENTS_FIELDS,
  SET_HOLIDAY_GROUPS,
  SET_ALL_FIELDS,
  SET_FORM_GROUPS,
} from '../actionTypes';
import { store } from 'app/store';
import { clearLoadingAdmin, setLoadingAdmin } from './loaderActions';
import {
  categoryTypes,
  fieldsetTypes,
  formGroupTypes,
  optionTypes,
  unitTypes,
} from 'redux/reducers/constantsReducer';
import {
  accountFieldsModel,
  caseFieldsModel,
  contactFieldsModel,
  maintenancePlanFieldsModel,
  opportunityFieldsModel,
  quoteFieldsModel,
  serviceAppointmentFieldsModel,
  serviceContractFieldsModel,
  workOrderFieldsModel,
} from 'components/shared/form/fields';

// Get All Constants
export const getAllConstants = () => dispatch => {
  axios
    .post(`${apiUrl}/general/getAllConstants`, {
      headers: axiosHeaders,
    })
    .then(data => {
      const res = data?.data;
      if (showLogs) {
        console.log('getAllConstants :', res);
      }
      if (res?.data?.units) {
        let dispatchData = {};
        dispatchData.type = SET_UNITS;
        for (let i = 0; i < unitTypes.length; i++) {
          dispatchData[unitTypes[i]] = res?.data?.units?.filter(c => c?.type === unitTypes[i]);
        }
        dispatch(dispatchData);
      }
      if (res?.data?.options) {
        let dispatchData = {};
        dispatchData.type = SET_OPTIONS;
        for (let i = 0; i < optionTypes.length; i++) {
          dispatchData[optionTypes[i]] = res?.data?.options?.filter(c => c?.type === optionTypes[i]);
        }
        dispatch(dispatchData);
      }
      if (res?.data?.formGroups) {
        let dispatchData = {};
        dispatchData.type = SET_FORM_GROUPS;
        for (let i = 0; i < formGroupTypes.length; i++) {
          dispatchData[formGroupTypes[i]] = res?.data?.formGroups?.filter(c => c?.type === formGroupTypes[i]);
        }
        dispatch(dispatchData);
      }
      if (res?.data?.categories) {
        let dispatchData = {};
        dispatchData.type = SET_CATEGORIES;
        for (let i = 0; i < categoryTypes.length; i++) {
          dispatchData[categoryTypes[i]] = res?.data?.categories?.filter(c => c?.type === categoryTypes[i]);
        }
        dispatch(dispatchData);
      }
      if (res?.data?.fieldsets) {
        let dispatchData = {};
        dispatchData.type = SET_ALL_FIELDS;
        for (let i = 0; i < fieldsetTypes.length; i++) {
          dispatchData[fieldsetTypes[i]] = res?.data?.fieldsets?.filter(c => c?.object === fieldsetTypes[i]);
        }
        dispatch(dispatchData);
      }
      if (res?.data?.levels) {
        dispatch({
          type: SET_LEVELS,
          levels: res?.data?.levels,
        });
      }
      if (res?.data?.taxGroups) {
        dispatch({
          type: SET_TAX_GROUPS,
          taxGroups: res?.data?.taxGroups,
        });
      }
      if (res?.data?.taxGroups) {
        dispatch({
          type: SET_HOLIDAY_GROUPS,
          holidayGroups: res?.data?.holidayGroups,
        });
      }
      if (res?.data?.roles) {
        dispatch({
          type: SET_ROLES,
          roles: res?.data?.roles,
        });
      }
      if (res?.data?.schedulingConstraints) {
        dispatch({
          type: SET_SCHEDULING_CONSTRAINTS,
          schedulingConstraints: res?.data?.schedulingConstraints,
        });
      }
      if (res?.data?.operatingHours) {
        dispatch({
          type: SET_OPERATING_HOURS,
          operatingHours: res?.data?.operatingHours,
        });
      }
      if (res?.data?.territories) {
        dispatch({
          type: SET_TERRITORIES,
          territories: res?.data?.territories,
        });
      }
      if (res?.data?.skills) {
        dispatch({
          type: SET_SKILLS,
          skills: res?.data?.skills,
        });
      }
      if (res?.data?.workTypes) {
        dispatch({
          type: SET_WORK_TYPES,
          workTypes: res?.data?.workTypes,
        });
      }
      if (res?.data?.pricebooks) {
        dispatch({ type: SET_PRICEBOOKS, pricebooks: res?.data?.pricebooks });
      }
      if (res?.data?.holiday) {
        dispatch({ type: SET_PRICEBOOKS, holidayGroups: res?.data?.holiday });
      }
    })
    .catch(err => {
      console.log(err);
    });
};

// UNITS
export const getAllUnits = fromAdmin => dispatch => {
  if (fromAdmin) {
    dispatch({ type: SET_LOADING_ADMIN });
  } else {
    dispatch({ type: SET_LOADING });
  }
  axios
    .post(`${apiUrl}/units/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      const res = data?.data;
      if (showLogs) {
        console.log(res);
      }
      if (res?.status) {
        let dispatchData = {};
        dispatchData.type = SET_UNITS;
        for (let i = 0; i < unitTypes.length; i++) {
          dispatchData[unitTypes[i]] = res?.data?.filter(c => c?.type === unitTypes[i]);
        }
        dispatch(dispatchData);
      }
    })
    .catch(err => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      console.log(err);
    });
};

// OPTIONS
export const getAllOptions = fromAdmin => dispatch => {
  if (fromAdmin) {
    dispatch({ type: SET_LOADING_ADMIN });
  } else {
    dispatch({ type: SET_LOADING });
  }
  axios
    .post(`${apiUrl}/options/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      const res = data?.data;
      if (showLogs) {
        console.log(res);
      }
      if (res?.status) {
        let dispatchData = {};
        dispatchData.type = SET_OPTIONS;
        for (let i = 0; i < optionTypes.length; i++) {
          dispatchData[optionTypes[i]] = res?.data?.filter(c => c?.type === optionTypes[i]);
        }
        dispatch(dispatchData);
      }
    })
    .catch(err => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      console.log(err);
    });
};

// FORM GROUPS
export const getAllFormGroups = fromAdmin => dispatch => {
  // if (fromAdmin) {
  //   dispatch({ type: SET_LOADING_ADMIN });
  // } else {
  //   dispatch({ type: SET_LOADING });
  // }
  axios
    .post(`${apiUrl}/form_groups/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      // if (fromAdmin) {
      //   dispatch({ type: CLEAR_LOADING_ADMIN });
      // } else {
      //   dispatch({ type: CLEAR_LOADING });
      // }
      const res = data?.data;
      if (showLogs) {
        console.log('/form_groups/getAll', res);
      }
      if (res?.status) {
        let dispatchData = {};
        dispatchData.type = SET_FORM_GROUPS;
        for (let i = 0; i < formGroupTypes.length; i++) {
          dispatchData[formGroupTypes[i]] = res?.data?.filter(c => c?.type === formGroupTypes[i]);
        }
        dispatch(dispatchData);
      }
    })
    .catch(err => {
      // if (fromAdmin) {
      //   dispatch({ type: CLEAR_LOADING_ADMIN });
      // } else {
      //   dispatch({ type: CLEAR_LOADING });
      // }
      console.log(err);
    });
};

// CATEGORIES
export const getAllCategories = fromAdmin => dispatch => {
  if (fromAdmin) {
    dispatch({ type: SET_LOADING_ADMIN });
  } else {
    dispatch({ type: SET_LOADING });
  }
  axios
    .post(`${apiUrl}/category/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      const res = data?.data;
      if (showLogs) {
        console.log('/category/getAll', res);
      }
      if (res?.status) {
        let dispatchData = {};
        dispatchData.type = SET_CATEGORIES;
        for (let i = 0; i < categoryTypes.length; i++) {
          dispatchData[categoryTypes[i]] = res?.data?.filter(c => c?.type === categoryTypes[i]);
        }
        dispatch(dispatchData);
      }
    })
    .catch(err => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      console.log(err);
    });
};

// LEVELS
export const getAllLevels = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/levels/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/levels/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_LEVELS,
          levels: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// TAX GROUPS
export const getAllTaxGroups = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/tax/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/tax/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_TAX_GROUPS,
          taxGroups: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// HOLIDAY GROUPS
export const getAllHolidayGroups = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/holiday_groups/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/holiday_groups/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_HOLIDAY_GROUPS,
          holidayGroups: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// ROLES
export const getAllRoles = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/roles/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/roles/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_ROLES,
          roles: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// USER LIST
export const getAllUsersList = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/users/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/users/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_USERS_LIST,
          usersList: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// SchedulingConstraints
export const getAllSchedulingConstraints = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/scheduling/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/scheduling/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_SCHEDULING_CONSTRAINTS,
          schedulingConstraints: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// OperatingHours
export const getAllOperatingHours = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/operating_hours/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/operating_hours/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_OPERATING_HOURS,
          operatingHours: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// TERRITORIES
export const getAllTerritories = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/territory/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/territory/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_TERRITORIES,
          territories: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// SKILLS
export const getAllSkills = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/skills/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/skills/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_SKILLS,
          skills: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// WORK TYPES
export const getAllWorkTypes = () => dispatch => {
  store.dispatch(setLoadingAdmin());
  axios
    .post(`${apiUrl}/worktype/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      store.dispatch(clearLoadingAdmin());
      const res = data?.data;
      if (showLogs) {
        console.log(`/worktype/getAll`, res);
      }
      if (res?.status) {
        dispatch({
          type: SET_WORK_TYPES,
          workTypes: res?.data,
        });
      }
    })
    .catch(err => {
      store.dispatch(clearLoadingAdmin());
      console.log(err);
    });
};

// FIELDS

export const getAccountFields = () => dispatch => {
  dispatch({ type: SET_ACCOUNT_FIELDS, fields: accountFieldsModel() });
};

export const getContactFields = () => dispatch => {
  dispatch({ type: SET_CONTACT_FIELDS, fields: contactFieldsModel() });
};

export const getServiceContractFields = () => dispatch => {
  dispatch({ type: SET_SERVICE_CONTRACT_FIELDS, fields: serviceContractFieldsModel() });
};

export const getMaintenancePlanFields = () => dispatch => {
  dispatch({ type: SET_MAINTENANCE_PLANS_FIELDS, fields: maintenancePlanFieldsModel() });
};

export const getCaseFields = () => dispatch => {
  dispatch({ type: SET_CASE_FIELDS, fields: caseFieldsModel() });
};

export const getOpportunityFields = () => dispatch => {
  dispatch({ type: SET_OPPORTUNITY_FIELDS, fields: opportunityFieldsModel() });
};

export const getQuoteFields = () => dispatch => {
  dispatch({ type: SET_QUOTES_FIELDS, fields: quoteFieldsModel() });
};

export const getWorkOrderFields = () => dispatch => {
  dispatch({ type: SET_WORK_ORDER_FIELDS, fields: workOrderFieldsModel() });
};

export const getServiceAppointmentFields = () => dispatch => {
  dispatch({ type: SET_SERVICE_APPOINTMENTS_FIELDS, fields: serviceAppointmentFieldsModel() });
};

export const getAllFieldsets = fromAdmin => dispatch => {
  if (fromAdmin) {
    dispatch({ type: SET_LOADING_ADMIN });
  } else {
    dispatch({ type: SET_LOADING });
  }
  axios
    .post(`${apiUrl}/fieldset/getAll`, {
      headers: axiosHeaders,
    })
    .then(data => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      const res = data?.data;
      if (showLogs) {
        console.log(`/fieldset/getAll`, res);
      }
      if (res?.status) {
        let dispatchData = {};
        dispatchData.type = SET_ALL_FIELDS;
        for (let i = 0; i < fieldsetTypes.length; i++) {
          dispatchData[fieldsetTypes[i]] = res?.data?.filter(c => c?.object === fieldsetTypes[i]);
        }
        dispatch(dispatchData);
      }
    })
    .catch(err => {
      if (fromAdmin) {
        dispatch({ type: CLEAR_LOADING_ADMIN });
      } else {
        dispatch({ type: CLEAR_LOADING });
      }
      console.log(err);
    });

  dispatch({ type: SET_SERVICE_APPOINTMENTS_FIELDS, fields: serviceAppointmentFieldsModel() });
  dispatch({ type: SET_WORK_ORDER_FIELDS, fields: workOrderFieldsModel() });
  dispatch({ type: SET_QUOTES_FIELDS, fields: quoteFieldsModel() });
  dispatch({ type: SET_OPPORTUNITY_FIELDS, fields: opportunityFieldsModel() });
  dispatch({ type: SET_CASE_FIELDS, fields: caseFieldsModel() });
  dispatch({ type: SET_MAINTENANCE_PLANS_FIELDS, fields: maintenancePlanFieldsModel() });
  dispatch({ type: SET_SERVICE_CONTRACT_FIELDS, fields: serviceContractFieldsModel() });
  dispatch({ type: SET_CONTACT_FIELDS, fields: contactFieldsModel() });
  dispatch({ type: SET_ACCOUNT_FIELDS, fields: accountFieldsModel() });
};
