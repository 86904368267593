import AdminHeader from 'components/layouts/header/AdminHeader';
import ModalForm from 'components/shared/Modal/ModalForm';
import React, { useEffect, useState } from 'react';
import OptionsForm from './components/OptionsForm';
import { connect } from 'react-redux';
import { addOption } from 'utils/functions/admin/options-func';
import { getAllOptions } from 'redux/actions/constantsActions';
import { useLocation } from 'react-router';
import OptionInput from './components/OptionInput';

const Options = ({ type, img, name, data, getAllOptions }) => {
  const location = useLocation();
  const [dataShowing, setDataShowing] = useState(data?.[type]);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  useEffect(() => {
    setDataShowing(data?.[type]);
    // eslint-disable-next-line
  }, [location?.pathname, data]);

  useEffect(() => {
    getAllOptions(true);
    // eslint-disable-next-line
  }, [location?.pathname]);

  return (
    <>
      <ModalForm
        modal={modalAdd}
        toggle={toggleAdd}
        title={`Add New ${name}`}
        confirmBtnText='Add'
        initialValues={null}
        onConfirm={values => addOption({ ...values, key: values?.key?.replaceAll(' ', '_') }, type)}
      >
        <OptionsForm type={type} />
      </ModalForm>

      <AdminHeader
        name={name}
        img={img}
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllOptions(true)}
      />

      <div className='row m-0'>
        {dataShowing?.map(item => (
          <div
            className='col-12 col-md-6'
            key={item?.id}
          >
            <OptionInput option={item} />
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.options,
  };
};

export default connect(mapStateToProps, { getAllOptions })(Options);
