import AdminHeader from 'components/layouts/header/AdminHeader';
import React, { useEffect, useState } from 'react';
import FieldsetItem from './components/FieldsetItem';
import { connect } from 'react-redux';
import { addFieldset } from 'utils/functions/admin/fieldset-func';
import { getAllFieldsets } from 'redux/actions/constantsActions';
import { useLocation } from 'react-router';
import ModalFieldset from './components/ModalFieldset';

const Fieldsets = ({ object, img, data, getAllFieldsets }) => {
  const location = useLocation();

  const [dataShowing, setDataShowing] = useState(data?.[object]);
  const [modalAdd, setModalAdd] = useState(false);

  const toggleAdd = () => {
    setModalAdd(prevState => !prevState);
  };

  useEffect(() => {
    setDataShowing(data?.[object]);
    // eslint-disable-next-line
  }, [location?.pathname, data]);

  useEffect(() => {
    getAllFieldsets(true);
    // eslint-disable-next-line
  }, [location?.pathname]);

  return (
    <>
      <ModalFieldset
        modal={modalAdd}
        toggle={toggleAdd}
        title={`Add New ${object}`}
        confirmBtnText='Add'
        item={{}}
        onConfirm={values => addFieldset(values, object)}
      />

      <AdminHeader
        name={`Fieldsets > ${object}`}
        img={img}
        onAddNew={() => toggleAdd()}
        onUpdate={() => getAllFieldsets(true)}
        showSearch={false}
      />
      <div className='d-flex row m-0 p-0 col-12 my-1'>
        {dataShowing?.map((item, i) => (
          <div
            key={item?.id}
            className='col-12 col-md-6 col-xl-3 col-xxl-2 mb-2 py-1'
          >
            <FieldsetItem field={item} />
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    data: state?.constants?.fieldsets,
  };
};

export default connect(mapStateToProps, { getAllFieldsets })(Fieldsets);
