export const axiosHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
};

export const axiosHeadersFormData = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
};
