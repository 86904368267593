import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
// import { GoogleApis } from 'googleapis';

const ModalCalender = ({ modal, toggle }) => {
  // const calendar = google.calendar('v3');
  // console.log(calendar);
  // console.log(GoogleApis);
  return (
    <>
      <div>
        <Modal
          show={modal}
          onHide={toggle}
          className='modal_lg'
        >
          <Modal.Header
            closeButton
            className='pb-3 mt-3'
          >
            <Modal.Title className='text-center w-100'>Calender</Modal.Title>
          </Modal.Header>

          <Modal.Body className='pt-0'>
            <hr className='mt-1' />
            <div className='row m-0'>caleeeender</div>
          </Modal.Body>
          {/* <Modal.Footer className='text-center w-100'>
            <Button
              className='mx-auto'
              variant='secondary'
              onClick={() => toggle(false)}
              style={{ width: '90px' }}
            >
              Cancel
            </Button>
            <Button
              className='mx-auto'
              variant='success'
              // onClick={() => onSubmit()}
              style={{ width: '100px' }}
            >
              Save
            </Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ModalCalender);
