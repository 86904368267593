import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router';

const ModalApp = ({ modal, toggle }) => {
	const navigate = useNavigate();
	const handleSelectApp = (route) => {
		toggle();
		navigate(`/${route}`);
	};
	return (
		<div>
			<Modal show={modal} onHide={toggle} className="modal_app">
				<Modal.Header>
					<Modal.Title className="text-center w-100 display-6">
						Applications
					</Modal.Title>
				</Modal.Header>

				<Modal.Body className="mt-5">
					<div className="row m-0">
						<div className="col-12 col-md-6 mx-auto text-center">
							<button
								type="button"
								className="application_btn rounded border-0 bg-transparent p-3 h-100 d-flex flex-column justify-content-around align-items-center mx-auto"
								onClick={() => handleSelectApp('dashboard')}
							>
								<div className="d-flex justify-content-center align-items-center">
									<img
										alt=""
										className="logo_app"
										src={`${process.env.PUBLIC_URL}/assets/img/logo.png`}
									/>
								</div>
								<h6 className="mt-4">SUPERIOR</h6>
							</button>
						</div>
						<div className="col-12 col-md-6 mx-auto text-center">
							<button
								type="button"
								className="application_btn rounded border-0 bg-transparent p-3 h-100 d-flex flex-column justify-content-around align-items-center mx-auto"
								onClick={() => handleSelectApp('admin')}
							>
								<div className="d-flex justify-content-center align-items-center">
									<img
										alt=""
										className="logo_app"
										src={`${process.env.PUBLIC_URL}/assets/img/admin.png`}
									/>
								</div>
								<h6 className="mt-4">ADMINISTRATION</h6>
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ModalApp;
