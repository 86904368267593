import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

const CategoryForm = ({ type, assetPropertyTypes }) => {
  const location = useLocation();

  const [fields, setFields] = useState([]);

  useEffect(() => {
    const basicFields = [
      {
        id: uuid()?.slice(0, 6),
        name: 'name',
        label: 'Name',
        type: 'text',
        component: 'input',
        required: '1',
      },
      {
        id: uuid()?.slice(0, 6),
        name: 'status',
        label: 'Is Active',
        type: 'checkbox',
        component: 'input',
        defaultValue: 1,
      },
    ];
    if (type === 'asset-property') {
      setFields([
        ...basicFields,
        {
          id: uuid()?.slice(0, 6),
          name: 'slug',
          label: 'Type',
          component: 'select',
          required: '1',
          options: assetPropertyTypes,
        },
      ]);
    } else if (type === 'resource-priority') {
      setFields([
        ...basicFields,
        {
          id: uuid()?.slice(0, 6),
          name: 'slug',
          label: 'Priority',
          type: 'number',
          component: 'input',
          required: '1',
          options: assetPropertyTypes,
        },
      ]);
    } else {
      setFields([
        ...basicFields,
        {
          id: uuid()?.slice(0, 6),
          name: 'slug',
          label: 'Description',
          type: 'text',
          component: 'input',
          required: '1',
        },
      ]);
    }
    // eslint-disable-next-line
  }, [type, location?.pathname]);

  return (
    <>
      <div className='row m-0'>
        {fields
          ?.filter(i => i.component === 'input' && i.type !== 'checkbox')
          .map(item => (
            <div
              className='col-12 col-md-6'
              key={item?.id}
            >
              <div className='mt-3'>
                <label className='form-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-control'
                  type={item?.type}
                  required={item?.required === '1'}
                />
              </div>
            </div>
          ))}
        {fields
          ?.filter(i => i.component === 'select')
          .map(item => (
            <div
              className='col-12 col-md-6 mt-3'
              key={item?.id}
            >
              <label className='form-label'>{item?.label}</label>
              <Field
                name={item?.name}
                component='select'
                className='form-control'
                required={item?.required === '1'}
              >
                <option value=' '>-- Select --</option>
                {item?.options?.map(opt => (
                  <option
                    value={opt?.name}
                    key={opt?.id}
                  >
                    {opt?.name}
                  </option>
                ))}
              </Field>
            </div>
          ))}

        <div className='form-check col-12 col-md-6 px-5 pt-4 d-flex justify-content-between'>
          {fields
            ?.filter(i => i.type === 'checkbox')
            .map(item => (
              <div key={item?.id}>
                <label className='form-check-label'>{item?.label}</label>
                <Field
                  name={item?.name}
                  component='input'
                  className='form-check-input'
                  type={item?.type}
                  required={item?.required === '1'}
                  defaultValue={item?.defaultValue}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    assetPropertyTypes: state?.constants?.categories?.['asset-property-type'],
  };
};

export default connect(mapStateToProps)(CategoryForm);
