import { LOG_OUT, SET_PRICEBOOKS, SET_SERVICE_RESOURCES } from '../actionTypes';

const initialState = null;

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICEBOOKS:
      return {
        ...state,
        pricebooks: action?.pricebooks,
      };
    case SET_SERVICE_RESOURCES:
      return {
        ...state,
        serviceResources: action?.serviceResources?.filter(item => item?.DeletedByID === null),
      };
    case LOG_OUT:
      return null;
    default:
      return state;
  }
};

export default mainReducer;
